'use strict';
import FluxConstant from 'flux-constant';


export default FluxConstant.set([
    'RESET',
    'PATCH_DETAILS',
    'PATCH_DETAILS_RESPONSE',
    'GET_RESULTS',
    'GET_RESULTS_RESPONSE',
    'RESET_RESULTS'
]);
