'use strict';


const parseReason = function (message) {

    const regexBecause = /because \[(.*?)(\]|$)/;
    let parsedMessage = message;

    if (regexBecause.test(message)) {
        parsedMessage = regexBecause.exec(message)[1];
    }

    if (regexBecause.test(parsedMessage)) {
        parsedMessage = parseReason(parsedMessage);
    }

    return parsedMessage;
};


/*
This a typical error
{
    statusCode: 403,
    error: "Forbidden",
    message: "Insufficient scope"
}

A Joi error looks different however.
{
    "statusCode":400,
    "error":"Bad Request",
    "message":"child \"First Name\" fails because [\"First Name\" is not allowed to be empty]. child \"Last Name\" fails because [\"Last Name\" is not allowed to be empty]",
    "validation":{
        "source":"payload",
        "keys":["first","last"]
    }
}
 */
const parseValidation = function (response) {

    const validation = response && response.validation;
    const message = response && response.message;
    const error = response && response.error;
    const result = {
        error,
        hasError: {},
        help: {}
    };

    let messages;
    if (message) {
        messages = message.split('.');
    } else {
        messages = [message];
    }
    if (validation && validation.keys && validation.keys.pop) {//hapi 17 doesn't send keys by default
        //If you set .options({abortEarly: false}) you get all the errors.
        //Hence the forEach
        validation.keys.forEach((forField, i) => {
            const thisMessage = messages.length > i ? messages[i] : '';
            const reason = parseReason(thisMessage);
            result.hasError[forField] = true;
            result.help[forField] = reason.trim();
        });
    }
    else if (message) {
        result.error = message;
    }
    else if (error) {
        result.error = error;
    }

    return result;
};


export default parseValidation;
