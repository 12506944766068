import Actions from '../actions';
import Store from '../store.js';
import Constants from '../constants';
import {initialState as InitialState} from "../client-sessions/current/reducers/current";
import _cloneDeep from 'lodash/cloneDeep.js';
import ApiActions from '../../../actions/api.js';
import jsonFetch from "../../../helpers/json-fetch";
import Log from "../../../helpers/log";
import Moment from 'moment';
import idbReady from 'safari-14-idb-fix';

const GlobalDbName = 'global-db';
const GlobalDbVersion = 1;
const SEND_QUEUE = 'sendQueue';
const SEND_QUEUE_INDEX = 'index';

const AccountDbName = 'account-db';


const CURRENT_DATA = 'currentData';

//Entities
const Entities = {
    ENTITY_ACCOUNTS: {indexedDbTable: 'Entity-Accounts', flatTable: 'Accounts'},
    ENTITY_CLIENT_SESSIONS: {indexedDbTable: 'Entity-Client-Sessions', flatTable: 'ClientSessions'},
    ENTITY_CLIENT_SESSIONS_STATS: {indexedDbTable: 'Entity-Client-Sessions-Stats', flatTable: 'ClientSessionsStats'},
    ENTITY_CLIENTS: {indexedDbTable: 'Entity-Clients', flatTable: 'Clients'},
    ENTITY_CLIENTS_OACCOUNTS: {indexedDbTable: 'Entity-Client-OAccounts', flatTable: 'ClientsOAccounts'},
    ENTITY_CLIENT_ACTIVITIES: {indexedDbTable: 'Entity-Client-Activities', flatTable: 'ClientActivities'},

    ENTITY_LESSON_PLANS: {indexedDbTable: 'Entity-Lesson-Plans', flatTable: 'LessonPlans'},

    ENTITY_DATASHEETS: {indexedDbTable: 'Entity-DataSheets', flatTable: 'DataSheets'},
    ENTITY_DATASHEETS_PTARGETS: {indexedDbTable: 'Entity-DataSheets-PTargets', flatTable: 'DataSheetsPTargets'},
    ENTITY_DATASHEETS_SEQUENCES: {indexedDbTable: 'Entity-DataSheets-Sequences', flatTable: 'DataSheetsSequences'},
    ENTITY_DEV_DOMAINS: {indexedDbTable: 'Entity-Dev-Domains', flatTable: 'DevDomains'},
    ENTITY_LESSONS: {indexedDbTable: 'Entity-Lessons', flatTable: 'Lessons'},
    ENTITY_NOTES: {indexedDbTable: 'Entity-Notes', flatTable: 'Notes'},
    ENTITY_DEPT_POSITIONS: {indexedDbTable: 'Entity-Dept-Positions', flatTable: 'DeptPositions'},
    ENTITY_OACCOUNTS: {indexedDbTable: 'Entity-OAccounts', flatTable: 'OAccounts'},
    ENTITY_OACCOUNTS_OADMINGROUPS: {indexedDbTable: 'Entity-OAccounts-OAdminGroups', flatTable: 'OAccountsOAdminGroups'},
    ENTITY_PANTECEDENT_INTERVENTIONS: {
        indexedDbTable: 'Entity-PAntecedent-Interventions',
        flatTable: 'PAntecedentInterventions'
    },
    ENTITY_PCONSEQUENCE_INTERVENTIONS: {
        indexedDbTable: 'Entity-PConsequence-Interventions',
        flatTable: 'PConsequenceInterventions'
    },
    ENTITY_PLESSONS: {indexedDbTable: 'Entity-PLessons', flatTable: 'PLessons'},
    ENTITY_PPROBLEM_BXES: {indexedDbTable: 'Entity-PProblem-Bxes', flatTable: 'PProblemBxes'},
    ENTITY_PPROBLEM_BXES_HISTORY: {indexedDbTable: 'Entity-PProblem-Bxes-History', flatTable: 'PProblemBxesHistory'},
    ENTITY_PPROBLEM_BXES_DATASHEETS: {
        indexedDbTable: 'Entity-PProblem-Bxes-DataSheets',
        flatTable: 'PProblemBxesDataSheets'
    },
    ENTITY_PPROBLEM_BXES_DATASHEET_TYPES: {
        indexedDbTable: 'Entity-PProblem-Bxes-DataSheet-Types',
        flatTable: 'PProblemBxesDataSheetTypes'
    },
    ENTITY_PPROBLEM_BXES_GOALS: {
        indexedDbTable: 'Entity-PProblem-Bxes-Goals',
        flatTable: 'PPBxesGoals'
    },
    ENTITY_PPROBLEM_BXES_GOALS_PPROBLEM_BX_LESSONS: {
        indexedDbTable: 'Entity-PProblem-Bxes-Goals-PProblem-Bx-Lessons',
        flatTable: 'PPBxesGoalsLessons'
    },
    ENTITY_PPROBLEM_BXES_GOALS_PPROBLEM_BX_LESSONS_DATA_SHEETS: {
        indexedDbTable: 'Entity-PProblem-Bxes-Goals-PProblem-Bx-Lessons-Data-Sheets',
        flatTable: 'PPBxesGoalsLessonsDataSheets'
    },
    ENTITY_PPROBLEM_BXES_GOALS_PPROBLEM_BX_LESSONS_DATA_SHEET_TYPES: {
        indexedDbTable: 'Entity-PProblem-Bxes-Goals-PProblem-Bx-Lessons-Data-Sheet-Types',
        flatTable: 'PPBxesGoalsLessonsDataSheetTypes'
    },
    ENTITY_PPROBLEM_BXES_LESSONS: {indexedDbTable: 'Entity-PProblem-Bxes-Lessons', flatTable: 'PProblemBxesLessons'},
    ENTITY_PSDRS: {indexedDbTable: 'Entity-PSdRs', flatTable: 'PSdRs'},
    ENTITY_PSDRS_DATASHEETS: {indexedDbTable: 'Entity-PSdRs-DataSheets', flatTable: 'PSdRsDataSheets'},
    ENTITY_MAINTENANCEPSDRS: {indexedDbTable: 'Entity-MaintenancePSdR', flatTable: 'MaintenancePSdRs'},
    ENTITY_PTARGETS: {indexedDbTable: 'Entity-PTargets', flatTable: 'PTargets'},
    ENTITY_TRAILS: {indexedDbTable: 'Entity-Trials', flatTable: 'Trials'},
    ENTITY_USERS: {indexedDbTable: 'Entity-Users', flatTable: 'Users'},

    // Session Notes
    ENTITY_FUNDING_SOURCES_GROUPS: {indexedDbTable: 'Entity-Funding-Sources-Groups', flatTable: 'FundingSourcesGroups'},
    ENTITY_FUNDING_SOURCES: {indexedDbTable: 'Entity-Funding-Sources', flatTable: 'FundingSources'},
    ENTITY_SESSION_NOTES_TYPES: {indexedDbTable: 'Entity-Sesssion-Notes-Types', flatTable: 'SessionNotesTypes'},
    ENTITY_SERVICE_TYPES: {indexedDbTable: 'Entity-Service-Types', flatTable: 'ServiceTypes'},
    ENTITY_CPT_CODES: {indexedDbTable: 'Entity-CPT-Codes', flatTable: 'CPTCodes'},
    ENTITY_SESSION_NOTES: {indexedDbTable: 'Entity-Session-Notes', flatTable: 'SessionNotes'},
    ENTITY_SESSION_NOTES_MATCHING_RULES: {indexedDbTable: 'Entity-Session-Notes-Matching-Rules', flatTable: 'SessionNotesMatchingRules'},
    ENTITY_SESSION_NOTES_LOCATIONS: {indexedDbTable: 'Entity-Session-Notes-Locations', flatTable: 'SessionNotesLocations'},
    
    ENTITY_APPOINTMENTS: {indexedDbTable: 'Entity-Appointments', flatTable: 'Appointments'},
    ENTITY_SESSION_NOTES_ENTRIES: {indexedDbTable: 'Entity-Session-Notes-Entries', flatTable: 'SessionNotesEntries'},
    ENTITY_CLIENTS_AUTHORIZATIONS: {indexedDbTable: 'Entity-Clients-Authorizations', flatTable: 'ClientsAuthorizations'},
    ENTITY_CLIENTS_LOCATIONS: {indexedDbTable: 'Entity-Clients-Locations', flatTable: 'ClientsLocations'},
    ENTITY_GEO_LOCATIONS: {indexedDbTable: 'Entity-Geo-Locations', flatTable: 'GeoLocations'},

    ENTITY_SIGNIFICANT_EVENTS: {indexedDbTable: 'Entity-Significant-Events', flatTable: 'SignificantEvents'},

    ENTITY_SIGNER_TYPES: {indexedDbTable: 'Entity-Signer-Types', flatTable: 'SignerTypes'},
    ENTITY_SESSION_NOTES_ENTRIES_SIGNATURES: {indexedDbTable: 'Entity-Session-Notes-Entries-Signatures', flatTable: 'SessionNotesEntriesSignatures'},
};

const EntityList = Object.keys(Entities).map((key) => {
    return Entities[key];
});

const Collections = {
    COLLECTION_CLIENT_SESSIONS: {indexedDbTable: 'Collection-Client-Sessions', flatTable: 'ClientSessions'},
    COLLECTION_CLIENT_SESSIONS_STATS: {
        indexedDbTable: 'Collection-Client-Sessions-Stats',
        flatTable: 'ClientSessionsStats'
    },
    COLLECTION_CLIENTS: {indexedDbTable: 'Collection-Clients', flatTable: 'Clients'},
    COLLECTION_CLIENTS_OACCOUNTS: {indexedDbTable: 'Collection-Client-OAccounts', flatTable: 'ClientsOAccounts'},
    COLLECTION_CLIENT_ACTIVITIES: {indexedDbTable: 'Collection-Client-Activities', flatTable: 'ClientActivities'},
    COLLECTION_DATASHEETS: {indexedDbTable: 'Collection-DataSheets', flatTable: 'DataSheets'},
    COLLECTION_DATASHEETS_SEQUENCES: {indexedDbTable: 'Collection-DataSheets-Sequences', flatTable: 'DataSheetsSequences'},
    COLLECTION_NOTES: {indexedDbTable: 'Collection-Notes', flatTable: 'Notes'},
    COLLECTION_PLESSONS: {indexedDbTable: 'Collection-PLessons', flatTable: 'PLessons'},
    COLLECTION_PPROBLEM_BXES: {indexedDbTable: 'Collection-PProblem-Bxes', flatTable: 'PProblemBxes'},

    // Session Notes
    COLLECTION_SESSION_NOTES: {indexedDbTable: 'Collection-Session-Notes', flatTable: 'SessionNotes'},
    COLLECTION_APPOINTMENTS: {indexedDbTable: 'Collection-Appointments', flatTable: 'Appointments'},
    COLLECTION_SESSION_NOTES_ENTRIES: {indexedDbTable: 'Collection-Session-Notes-Entries', flatTable: 'SessionNotesEntries'},

    COLLECTION_SIGNIFICANT_EVENTS: {indexedDbTable: 'Collection-Significant-Events', flatTable: 'SignificantEvents'},

    COLLECTION_LESSON_PLANS: {indexedDbTable: 'Collection-Lesson-Plans', flatTable: 'LessonPlans'},
};

const CollectionList = Object.keys(Collections).map((key) => {
    return Collections[key];
});

//IndexedDB uses Event, DOMException or Error for errors.  Most of the references are DOM objects which don't serialize
//under JSON.stringify.  There is a serialize library https://github.com/sindresorhus/serialize-error
// But I'm not using it because I'm not sure about circular references.  Jim

//For testing change .put to .add to get an Event error.  Close the db as in db.close and then do an operation to get
//a DOMException error.  Throw a normal error to get a normal error.
const serializeError = (err) => {
    //obj has to be a new object because err is a DOMObject most likely and its properties dont' serialize
    //Also most keys are not enumerable so they don't show up under JSON.stringify.
    const obj = { ...err };//pick up anything that is enumerable.
    const target = err.target;
    if (err instanceof Event && target && target.error) {
        obj._type = 'Event';//for my own sanity.
        obj.target = {
            error: {
                code: target.error.code,
                message: target.error.message,
                name: target.error.name
            }
        };
        const source = target.source;
        if ( source ) {
            obj.target.source = {
                keyPath: source.keyPath,
                name: source.name
            };
        }
    }

    //These come either for Error and DOMException instanceof cases.  So just check for them.
    ['code', 'column', 'line', 'message', 'name', 'sourceUrl', 'stack'].forEach( (key) => {
        if (err[key]) {
            obj[key] =  err[key];
        }
    });
    if ( err instanceof DOMException) {
        //in case these are non enumerable
        obj._type = 'DOMException';
    }

    return obj;
};

let lastPut;
const putValue = (objectStore, key, value) => {
    return new Promise((resolve, reject) => {
        lastPut = value;
        try {
            const request = objectStore.put(value, key);
            request.onsuccess = function (event) {
                resolve(event);
            };

            request.onerror = function (event) {
                //this won't fire on Clone error
                //console.log('rejects', key, value, event);
                Log('IndexedDB Put Error', { event, serializedEvent: serializeError(event), key, value });
                reject(event);
            };
        } catch (e) {
            //console.log('catch before promise', key, value);
            reject(e);
        }
    });
};

const getValue = (objectStore, key) => {
    return new Promise((resolve, reject) => {
        const request = objectStore.get(key);
        request.onsuccess = function (event) {
            resolve(event.target.result);
        };

        request.onerror = function (event) {
            Log('IndexedDB Get Error', { event, serializedEvent: serializeError(event), key });
            reject(event);
        };
    });
};

const deleteValue = (objectStore, key) => {
    return new Promise((resolve, reject) => {
        const request = objectStore.delete(key);
        request.onsuccess = function (event) {
            resolve();
        };

        request.onerror = function (event) {
            Log('IndexedDB Delete Error', { event, serializedEvent: serializeError(event), key });
            reject(event);
        };
    });
};

const createStoreObjectIfNotExists = (db, objectStoreName) => {
    const objectStoreNames = db.objectStoreNames;
    if (!objectStoreNames.contains(objectStoreName))
    {
        db.createObjectStore(objectStoreName);
    }
}

const accountUpgrades = [];
//first upgrade
accountUpgrades.push((db) => {
    EntityList.forEach((entity) => {
        createStoreObjectIfNotExists(db, entity.indexedDbTable);
    });
    CollectionList.forEach((collection) => {
        createStoreObjectIfNotExists(db, collection.indexedDbTable);
    });
});

accountUpgrades.push((db) => {
    createStoreObjectIfNotExists(db, CURRENT_DATA);
});

// Required to keep track of existing version
accountUpgrades.push(() => {});

accountUpgrades.push((db) => {
    createStoreObjectIfNotExists(db, Entities.ENTITY_DATASHEETS_SEQUENCES.indexedDbTable);
    createStoreObjectIfNotExists(db, Collections.COLLECTION_DATASHEETS_SEQUENCES.indexedDbTable);
});

// Adding maintenance PSdRs
accountUpgrades.push((db) => {
    createStoreObjectIfNotExists(db, Entities.ENTITY_MAINTENANCEPSDRS.indexedDbTable);
});

// Adding Session Notes
accountUpgrades.push((db) => {
    createStoreObjectIfNotExists(db, Entities.ENTITY_FUNDING_SOURCES_GROUPS.indexedDbTable);
    createStoreObjectIfNotExists(db, Entities.ENTITY_FUNDING_SOURCES.indexedDbTable);
    createStoreObjectIfNotExists(db, Entities.ENTITY_SESSION_NOTES_TYPES.indexedDbTable);
    createStoreObjectIfNotExists(db, Entities.ENTITY_SERVICE_TYPES.indexedDbTable);
    createStoreObjectIfNotExists(db, Entities.ENTITY_CPT_CODES.indexedDbTable);
    createStoreObjectIfNotExists(db, Entities.ENTITY_SESSION_NOTES.indexedDbTable);
    createStoreObjectIfNotExists(db, Entities.ENTITY_SESSION_NOTES_MATCHING_RULES.indexedDbTable);
    createStoreObjectIfNotExists(db, Entities.ENTITY_APPOINTMENTS.indexedDbTable);
    createStoreObjectIfNotExists(db, Entities.ENTITY_SESSION_NOTES_ENTRIES.indexedDbTable);
    createStoreObjectIfNotExists(db, Entities.ENTITY_CLIENTS_AUTHORIZATIONS.indexedDbTable);
    createStoreObjectIfNotExists(db, Entities.ENTITY_CLIENTS_LOCATIONS.indexedDbTable);
    createStoreObjectIfNotExists(db, Entities.ENTITY_GEO_LOCATIONS.indexedDbTable);

    createStoreObjectIfNotExists(db, Collections.COLLECTION_SESSION_NOTES.indexedDbTable);
    createStoreObjectIfNotExists(db, Collections.COLLECTION_APPOINTMENTS.indexedDbTable);
    createStoreObjectIfNotExists(db, Collections.COLLECTION_SESSION_NOTES_ENTRIES.indexedDbTable);
});

// Adding missing properties
accountUpgrades.push((db) => {
    createStoreObjectIfNotExists(db, Entities.ENTITY_CLIENT_ACTIVITIES.indexedDbTable);
    createStoreObjectIfNotExists(db, Entities.ENTITY_DEPT_POSITIONS.indexedDbTable);
    createStoreObjectIfNotExists(db, Entities.ENTITY_OACCOUNTS_OADMINGROUPS.indexedDbTable);
    createStoreObjectIfNotExists(db, Entities.ENTITY_PSDRS_DATASHEETS.indexedDbTable);
    createStoreObjectIfNotExists(db, Entities.ENTITY_USERS.indexedDbTable);

    createStoreObjectIfNotExists(db, Collections.COLLECTION_CLIENT_ACTIVITIES.indexedDbTable);
});

// Added SignificantEvents
accountUpgrades.push((db) => {
    createStoreObjectIfNotExists(db, Entities.ENTITY_SIGNIFICANT_EVENTS.indexedDbTable);
    createStoreObjectIfNotExists(db, Collections.COLLECTION_SIGNIFICANT_EVENTS.indexedDbTable);
});

// Added SessionNotesLocations
accountUpgrades.push((db) => {
    createStoreObjectIfNotExists(db, Entities.ENTITY_SESSION_NOTES_LOCATIONS.indexedDbTable);
});

// Adding Lesson Plans
accountUpgrades.push((db) => {
    createStoreObjectIfNotExists(db, Entities.ENTITY_LESSON_PLANS.indexedDbTable);
    createStoreObjectIfNotExists(db, Collections.COLLECTION_LESSON_PLANS.indexedDbTable);
});

// Adding Session Note Signatures
accountUpgrades.push((db) => {
    createStoreObjectIfNotExists(db, Entities.ENTITY_SIGNER_TYPES.indexedDbTable);
    createStoreObjectIfNotExists(db, Entities.ENTITY_SESSION_NOTES_ENTRIES_SIGNATURES.indexedDbTable);
});

// Adding BIP New Goals
accountUpgrades.push((db) => {
    createStoreObjectIfNotExists(db, Entities.ENTITY_PPROBLEM_BXES_GOALS.indexedDbTable);
    createStoreObjectIfNotExists(db, Entities.ENTITY_PPROBLEM_BXES_GOALS_PPROBLEM_BX_LESSONS.indexedDbTable);
    createStoreObjectIfNotExists(db, Entities.ENTITY_PPROBLEM_BXES_GOALS_PPROBLEM_BX_LESSONS_DATA_SHEETS.indexedDbTable);
    createStoreObjectIfNotExists(db, Entities.ENTITY_PPROBLEM_BXES_GOALS_PPROBLEM_BX_LESSONS_DATA_SHEET_TYPES.indexedDbTable);
});

// Adding ENTITY_PPROBLEM_BXES_DATASHEET_TYPES for Revised BIP
accountUpgrades.push((db) => {
    createStoreObjectIfNotExists(db, Entities.ENTITY_PPROBLEM_BXES_DATASHEET_TYPES.indexedDbTable);
    createStoreObjectIfNotExists(db, Entities.ENTITY_PPROBLEM_BXES_HISTORY.indexedDbTable);
})

// New upgrade just push it to accountUpgrades array

const AccountDbVersion = accountUpgrades.length;

let idbCheck
const openDb = (dbName, dbVersion, upgrades) => {

    if ( !idbCheck ) {
        idbCheck = idbReady().then(() => {
            console.log('idb ready');
        });
    }
    return idbCheck.then(() => {
        return new Promise((resolve, reject) => {
            const indexedDB = window.indexedDB;
            //this could throw immediately or reject later
            //so callers don't have to handle both cases we catch and reject
            if (!indexedDB) {
                Actions.setIndexedDbStatus(false);
                return reject('IndexedDB not available');
            }

            const request = indexedDB.open(dbName, dbVersion);

            request.onerror = function (event) {
                Log('IndexedDB Open Error', { event, serializedEvent: serializeError(event), dbName });
                reject(event);
            };

            request.onupgradeneeded = function (event) {
                // Save the IDBDatabase interface
                const db = event.target.result;
                const oldVersion = event.oldVersion;
                const upgradesToDo = upgrades.slice(oldVersion);
                upgradesToDo.forEach((up) => {
                    // console.log('Version upgrade needed', dbName, up);
                    up(db);
                });

            };

            request.onsuccess = function (event) {
                resolve(event);
            };
        });
    });

};

const openAccountDb = (dbName) => {
    return openDb(dbName, AccountDbVersion, accountUpgrades);
};

const globalUpgrades = [];
globalUpgrades.push((db) => {
    const objectStore = db.createObjectStore(SEND_QUEUE);
    objectStore.createIndex(SEND_QUEUE_INDEX, 'index', { unique: true });
});

const openGlobalDb = () => {
    return openDb(GlobalDbName, GlobalDbVersion, globalUpgrades);
};

let previousCurrent;
let queue = [];
let errorThrown = null;

Store.subscribe(() => {
    //current is the entire flat store.
    //We save all of the changes everytime.
    const current = Store.getState().current;
    const clientId = current.currentClientId;
    //console.log('store update in indexDB');
    if (!clientId || current.initializingData) {
        //not ready
        return;
    }

    //did we switch clients?
    if (!previousCurrent || clientId !== previousCurrent.currentClientId) {
        previousCurrent = {
            currentClientId: undefined,
            currentClientSessionId: undefined,
            currentSessionNotes: [],
            currentSessionNotesEntryId: undefined,
            currentClientAppointments: {},
            permissions: {
                edit: false,
                view: false,
            },
            entities: {},
            collections: {}
        };
    }

    queue.push({clientId, previousCurrent, current});
    processQueue();
});

let processing = false;
const processQueue = () => {
    if (errorThrown) {
        console.log('Index DB has an error: ', errorThrown);
    }
    if (processing) {

        //console.log('called during processing', Store.getState().current);
        return;
    }
    if (queue.length === 0) {
        console.log('processing complete');
        return;
    }
    //console.log('processing', Store.getState().current.entities.DataSheets.byId, queue.length);
    processing = true;
    const job = queue.shift();
    const {clientId, previousCurrent: previous, current} = job;
    const dbName = getDbName(clientId);

    //console.log('checking state', current === previous, current);
    openAccountDb(dbName).then((event) => {
        const db = event.target.result;
        return new Promise((resolve, reject) => {
            db.onerror = function (event) {
                Log('IndexedDB OpenAccountDb Error', { event, serializedEvent: serializeError(event), dbName });
                reject(event);
            };

            const promises = [];
            const { currentClientId, currentClientSessionId, currentSessionNotesEntryId, currentSessionNotes, currentClientAppointments } = current;
            if (
                currentClientId !== previous.currentClientId 
                || currentClientSessionId !== previous.currentClientSessionId
                || currentSessionNotesEntryId !== previous.currentSessionNotesEntryId
                || (currentSessionNotes && currentSessionNotes.length !== previous.currentSessionNotes.length)
                || (currentClientAppointments && currentClientAppointments.data && currentClientAppointments.data.length !== previous.currentClientAppointments.data.length)
            ) {

                const transaction = db.transaction([CURRENT_DATA], "readwrite");
                const ccsObjectStore = transaction.objectStore(CURRENT_DATA);
                promises.push(putValue(ccsObjectStore, 'currentClientId', currentClientId));
                promises.push(putValue(ccsObjectStore, 'currentClientSessionId', currentClientSessionId));

                promises.push(putValue(ccsObjectStore, 'currentSessionNotesEntryId', currentSessionNotesEntryId));
                promises.push(putValue(ccsObjectStore, 'currentSessionNotes', currentSessionNotes));
                promises.push(putValue(ccsObjectStore, 'currentClientAppointments', currentClientAppointments));
            }

            //We check every table for changes via ===
            EntityList.forEach((entity) => {
                const { indexedDbTable, flatTable } = entity;
                //Flat tables look like ( byId: {},  allIds: [], networkStatusById: {} }
                //For each table we will see if any of the 3 have changed.
                const previousEntityTable = previous.entities[flatTable] || {
                    byId: {},
                    allIds: [],
                    networkStatusById: {}
                };
                const currentEntityTable = current.entities[flatTable];
                if (previousEntityTable !== currentEntityTable) {

                    const transaction = db.transaction([indexedDbTable], "readwrite");
                    const objectStore = transaction.objectStore(indexedDbTable);
                    promises.push(putValue(objectStore, 'allIds', currentEntityTable.allIds));

                    const prevById = previousEntityTable.byId;
                    const currById = currentEntityTable.byId;

                    //entityById
                    Object.keys(prevById).forEach((id) => {
                        if (!currById[id]) {
                            promises.push(deleteValue(objectStore, 'byId--' + id));
                        }
                    });
                    Object.keys(currById).forEach((id) => {
                        if (currById[id] !== prevById[id]) {
                            promises.push(putValue(objectStore, 'byId--' + id, currById[id]));
                        }
                    });

                    const prevNetworkStatusById = previousEntityTable.networkStatusById;
                    const currNetworkStatusById = currentEntityTable.networkStatusById;

                    //networkStatusById
                    Object.keys(prevNetworkStatusById).forEach((id) => {
                        if (!currNetworkStatusById[id]) {
                            promises.push(deleteValue(objectStore, 'networkStatusById--' + id));
                        }
                    });
                    Object.keys(currNetworkStatusById).forEach((id) => {
                        if (currNetworkStatusById[id] !== prevNetworkStatusById[id]) {
                            promises.push(putValue(objectStore, 'networkStatusById--' + id, currNetworkStatusById[id]));
                        }
                    });
                }
            });

            CollectionList.forEach((collection) => {
                const {indexedDbTable, flatTable} = collection;
                const previousCollectionTable = previous.collections[flatTable] || {
                    idsByQuery: {},
                    itemsByQuery: {},
                    pagesByQuery: {},
                    networkStatusByQuery: {}
                };

                const currentCollectionTable = current.collections[flatTable];
                if (previousCollectionTable !== currentCollectionTable) {

                    const transaction = db.transaction([indexedDbTable], "readwrite");
                    const objectStore = transaction.objectStore(indexedDbTable);

                    const prefixes = ['idsByQuery', 'itemsByQuery', 'pagesByQuery', 'networkStatusByQuery'];
                    prefixes.forEach((prefix) => {

                        const prevPrefixObj = previousCollectionTable[prefix];
                        const currPrefixObj = currentCollectionTable[prefix];
                        if (prevPrefixObj !== currPrefixObj) {
                            //idsByQuery for example
                            Object.keys(prevPrefixObj).forEach((id) => {
                                if (!currPrefixObj[id]) {
                                    promises.push(deleteValue(objectStore, prefix + '--' + id));
                                }
                            });

                            Object.keys(currPrefixObj).forEach((id) => {
                                if (currPrefixObj[id] !== prevPrefixObj[id]) {
                                    promises.push(putValue(objectStore, prefix + '--' + id, currPrefixObj[id]));
                                }
                            });
                        }
                    });
                }
            });

            Promise.all(promises).then(() => {
                resolve();
            }).catch((e) => {
                console.log('rejecting...', e);
                reject(e);
            });
        });

    }).then(() => {
        //console.log('done saving flat store to indexedDB');
        previousCurrent = current;
        processing = false;
        processQueue();
    }, (event) => {
        //this could be an event or an error
        //not making use of the distinction yet.
        errorThrown = event;
        Actions.setIndexedDbError(event);
        Log('IndexedDB Process Queue Error', { event, serializedEvent: serializeError(event), lastPut });
        console.log('Error on IndexedDB', event);
    });
};

const getDbName = (clientId) => {
    return AccountDbName + '-' + clientId;
};

class IndexedDB {

    static sendChangeQueue() {
        let db = null;
        return openGlobalDb().then((event) => {
            db = event.target.result;
            return new Promise((resolve, reject) => {
                const pendingRequests = [];
                db.onerror = function (event) {
                    Log('IndexedDB SendChangeQueue Error', { event, serializedEvent: serializeError(event), lastPut });
                    reject(event);
                };

                const transaction = db.transaction([SEND_QUEUE], 'readwrite');

                const objectStore = transaction.objectStore(SEND_QUEUE);
                const index = objectStore.index(SEND_QUEUE_INDEX);

                transaction.oncomplete = function (event) {
                    resolve(pendingRequests);
                };

                index.openCursor().onsuccess = function (event) {
                    const cursor = event.target.result;
                    if (cursor) {
                        //transactions commit when the db variable goes out of scope and there
                        //are no more pending requests.  A network call would allow that to happen
                        //so we read them all instead and do the network later.
                        pendingRequests.push(cursor.value);
                        cursor.continue();
                    }
                };
            }).then((queue) => {

                return new Promise((resolve, reject) => {
                    const sendRequest = () => {
                        //const queuedItem = queue.shift();
                        if (queue.length === 0) {
                            return resolve(queue);
                        }
                        const queuedItem = queue[0];
                        const request = queuedItem.request;
                        jsonFetch(request, (err) => {
                            console.log('init request returned', err);
                            if (err) {
                                return resolve(queue);//couldn't sent it
                            }

                            IndexedDB.unpersistChangeQueueItem(queuedItem.id).then(() => {
                                console.log('change queue item unpersisted');
                                queue.shift();
                                sendRequest();
                            }, (err) => {
                                console.log('Could not remove queue item from IndexedDB', err);
                                resolve(queue);
                            });

                        });
                    };
                    sendRequest();
                });
            }).then((queue) => {
                console.log("queue left after init upload", queue);
                //these are still in IndexedDb, just need to put them back in the
                ApiActions.frontLoadChangeQueue(queue, Store, Constants.UPLOAD_OFFLINE_DATA, Constants.UPLOAD_OFFLINE_DATA_RESPONSE);
            }).finally(() => {
                if (db !== null) {
                    db.close();
                }
            });
        });
    }

    static persistChangeQueueItem(id, request) {
        if (errorThrown) {
            console.log('Index DB has an error: ', errorThrown);
        }

        let db = null;
        return openGlobalDb().then((event) => {
            db = event.target.result;
            return new Promise((resolve, reject) => {
                db.onerror = function (event) {
                    Log('IndexedDB Persist ChangeQueue Error', { event, serializedEvent: serializeError(event), lastPut });
                    reject(event);
                };

                const transaction = db.transaction([SEND_QUEUE], "readwrite");
                const objectStore = transaction.objectStore(SEND_QUEUE);
                const cursor = objectStore.index(SEND_QUEUE_INDEX).openCursor(null, 'prev');
                let index = 1;
                cursor.onsuccess = function (event) {
                    if (event.target.result) {
                        index = event.target.result.key + 1;
                    }

                    putValue(objectStore, id, { id, index, request }).then((event) => {
                        resolve(event);
                    }, (event) => {
                        Log('IndexedDB Persist ChangeQueue Put Value Error', { event, serializedEvent: serializeError(event), lastPut });
                        reject(event);
                    });
                };
            });

        }).catch((event) => {
            //this could be an event or an error
            //not making use of the distinction yet.
            errorThrown = event;
            Actions.setIndexedDbError(event);
            Log('IndexedDB Persist ChangeQueueItem Error', { event, serializedEvent: serializeError(event), lastPut });
            console.log('error on IndexedDB', event);
        }).finally(() => {
            if (db !== null) {
                db.close();
            }
        });;
    };

    static unpersistChangeQueueItem(id) {
        if (errorThrown) {
            console.log('Index DB has an error: ', errorThrown);
        }

        let db = null;
        return openGlobalDb().then((event) => {
            db = event.target.result;
            return new Promise((resolve, reject) => {
                db.onerror = function (event) {
                    Log('IndexedDB UnpersistChangeQueue Error', { event, serializedEvent: serializeError(event), lastPut });
                    reject(event);
                };

                const transaction = db.transaction([SEND_QUEUE], "readwrite");
                const ccsObjectStore = transaction.objectStore(SEND_QUEUE);
                deleteValue(ccsObjectStore, id).then((event) => {
                    resolve(event);
                }, (event) => {
                    Log('IndexedDB UnpersistChangeQueueItem Delete Error', { event, serializedEvent: serializeError(event), lastPut });
                    reject(event);
                });
            });

        }).catch((event) => {
            //this could be an event or an error
            //not making use of the distinction yet.
            errorThrown = event;
            Actions.setIndexedDbError(event);
            Log('IndexedDB UnpersistChangeQueueItem Error', { event, serializedEvent: serializeError(event), lastPut });
            console.log('error on IndexedDB', event);
        }).finally(() => {
            if (db !== null) {
                db.close();
            }
        });;
    };

    static getSavedFlatTable(clientId) {

        const dbName = getDbName(clientId);
        const state = _cloneDeep(InitialState);
        state.initializingData = false;
        return openAccountDb(dbName).then((event) => {
            const db = event.target.result;

            return new Promise((resolve, reject) => {
                db.onerror = function (event) {
                    Log('IndexedDB GetSavedFlatTable onerror Error', { event, serializedEvent: serializeError(event), lastPut });
                    reject(event);
                };

                const promises = [];
                const transaction = db.transaction([CURRENT_DATA], 'readonly');
                const ccsObjectStore = transaction.objectStore(CURRENT_DATA);
                promises.push(getValue(ccsObjectStore, 'currentClientId').then((clientId) => {
                    state.currentClientId = clientId;
                }));
                promises.push(getValue(ccsObjectStore, 'currentClientSessionId').then((clientSessionId) => {
                    state.currentClientSessionId = clientSessionId;
                }));
                
                promises.push(getValue(ccsObjectStore, 'currentSessionNotes').then((sessionNotes) => {
                    state.currentSessionNotes = sessionNotes;
                }));
                promises.push(getValue(ccsObjectStore, 'currentSessionNotesEntryId').then((sessionNotesEntryId) => {
                    state.currentSessionNotesEntryId = sessionNotesEntryId;
                }));
                promises.push(getValue(ccsObjectStore, 'currentClientAppointments').then((clientAppointments) => {
                    state.currentClientAppointments = clientAppointments;
                }));

                EntityList.forEach((entity) => {
                    const {indexedDbTable, flatTable} = entity;
                    const entityTable = state.entities[flatTable];

                    const transaction = db.transaction([indexedDbTable]);
                    const promise = new Promise((resolve, reject) => {

                        transaction.oncomplete = function (event) {
                            resolve(event);
                        };

                        const objectStore = transaction.objectStore(indexedDbTable);
                        objectStore.openCursor().onsuccess = function (event) {
                            const cursor = event.target.result;
                            if (cursor) {
                                const value = cursor.value;
                                if (cursor.key === 'allIds') {
                                    entityTable.allIds = value;
                                } else {
                                    const split = cursor.key.split('--');
                                    const subKey = split[0];
                                    const key = split[1];
                                    if (subKey === 'networkStatusById') {
                                        entityTable.networkStatusById[key] = value;
                                    } else {
                                        entityTable.byId[key] = value;
                                    }
                                }
                                cursor.continue();
                            }
                        };
                    });
                    promises.push(promise);
                });

                CollectionList.forEach((collection) => {
                    const {indexedDbTable, flatTable} = collection;
                    const collectionTable = state.collections[flatTable];
                    /*{
                        idsByQuery: {}
                        itemsByQuery: {}
                        pagesByQuery: {}
                    }*/

                    const transaction = db.transaction([indexedDbTable]);
                    const promise = new Promise((resolve, reject) => {

                        transaction.oncomplete = function (event) {
                            resolve(event);
                        };

                        const objectStore = transaction.objectStore(indexedDbTable);
                        objectStore.openCursor().onsuccess = function (event) {
                            const cursor = event.target.result;
                            if (cursor) {
                                const split = cursor.key.split('--');
                                const subKey = split[0];
                                const key = split[1];
                                const value = cursor.value;
                                if (subKey === 'idsByQuery') {
                                    collectionTable.idsByQuery[key] = value;
                                } else if (subKey === 'pagesByQuery') {
                                    collectionTable.pagesByQuery[key] = value;
                                } else if (subKey === 'itemsByQuery') {
                                    collectionTable.itemsByQuery[key] = value;
                                } else if (subKey === 'networkStatusByQuery') {
                                    collectionTable.networkStatusByQuery[key] = value;
                                }
                                cursor.continue();
                            }
                        };
                    });
                    promises.push(promise);
                });
                //console.log('length', promises.length);
                return Promise.all(promises).then(() => {
                    resolve(state);
                }).catch((e) => {
                    reject(e);
                });
            });
        }).catch((event) => {
            errorThrown = event;
            Actions.setIndexedDbError(event);
            Log('IndexedDB GetSavedFlatTable Error', { event, serializedEvent: serializeError(event), lastPut });
            console.log('error on IndexedDB', event);
        });
    }

    static evictOldData({currentClientId}) {
        const indexedDB = window.indexedDB;
        if ( !indexedDB ) {
            throw new Error('indexedDB not available');
        }

        let db = null;
        return openGlobalDb().then((event) => {

            db = event.target.result;
            return new Promise((resolve, reject) => {
                db.onerror = function (event) {
                    Log('IndexedDB CheckChangeQueue Error', { event, serializedEvent: serializeError(event) });
                    reject(event);
                };

                const transaction = db.transaction([SEND_QUEUE], 'readonly');
                const objectStore = transaction.objectStore(SEND_QUEUE);
                const countRequest = objectStore.count();
                countRequest.onsuccess = function () {
                    resolve(countRequest.result);
                };
            }).then((pending) => {
                db.close();
                return pending;
            });
        }).then((pending) => {

            if (pending === 0 && indexedDB.databases) {
                //we only clean out if there are no updates.
                return indexedDB.databases()
                // No need to filter out current id because we'll need to clean it up as well in case of too much data
                .then((databases) => {
                    // Filter out global-db
                    return databases.filter((db) => {
                        const name = db.name;
                        // const current = 'account-db-' + currentClientId;
                        // if (name === current) {
                        //     //don't delete it if we want to look at it.
                        //     return false;
                        // }

                        return name.indexOf('account-db') === 0;
                    });
                })
                .then((accountDbs) => {
                    // We'll look at all the accounts
                    // if (accountDbs.length < 5) {
                    //     //we keep the top 4
                    //     return [];
                    // }

                    const current = 'account-db-' + currentClientId;
                    const now = Moment();
                    const keeps = {};
                    const promises = accountDbs.map((item) => {
                        keeps[item.name] = false;
                        return new Promise((resolve, reject) => {
                            if (item.name === current) {
                                return resolve();
                            }

                            return openAccountDb(item.name).then((event) => {
                                const db = event.target.result;
                                db.onerror = function (event) {
                                    Log('IndexedDB CheckEvict Error', { event, serializedEvent: serializeError(event) });
                                    reject(event);
                                };

                                const transaction = db.transaction([Entities.ENTITY_DATASHEETS.indexedDbTable], 'readonly');
                                transaction.oncomplete = (event) => {
                                    db.close();//close this or the db will get hung up on delete.
                                    resolve();
                                };
                                
                                // We'll remove current so just look at other accounts and remove any that older than 3 days will be removed
                                const objectStore = transaction.objectStore(Entities.ENTITY_DATASHEETS.indexedDbTable);
                                    const cursorRequest = objectStore.openCursor();
                                    cursorRequest.onsuccess = (event) => {
                                        const cursor = event.target.result;
                                        if (cursor) {
                                            const val = cursor.value;
                                            if (val.updatedAt) {
                                                const diff = now.diff(Moment(val.updatedAt), 'days');
                                                if (diff <= 3) {
                                                    keeps[item.name] = true;
                                                    //we might be able to return right here and now call cursor.continue to get to transaction.oncomplete.  Not sure.  Jim
                                                }
                                            }
    
                                            cursor.continue();
                                        }
                                    };
                            });
                        });
                    });
                    return Promise.all(promises).then(() => {
                        const removes = Object.keys(keeps).filter(key => !keeps[key]);
                        return removes;
                    });
                }).then((removes) => {
                    //console.log('Removing old account data:', removes);
                    return Promise.all(removes.map((name) => {

                        return new Promise((resolve, reject) => {
                            console.log('Removing ', name);
                            const deleteRequest = indexedDB.deleteDatabase(name);
                            deleteRequest.onsuccess = function (event) {
                                console.log('Removed ', name);
                                resolve();
                            };

                            deleteRequest.onerror = function (event) {
                                console.log('Unable to remove ', name);
                                reject(event);
                            };

                        });
                    }));
                });
            }

            return Promise.resolve();
        }).finally(() => {
            if (db !== null) {
                db.close();
            }
        });;
    }

    static emptyOutAllDataExceptGlobalDB() {
        return new Promise((resolve, reject) => {
            const indexedDB = window.indexedDB;
            if ( !indexedDB ) {
                reject('indexedDB not available');
            }

            if (!indexedDB.databases) {
                return resolve();
            }

            return indexedDB.databases()
            // No need to filter out current id because we'll need to clean it up as well in case of too much data
            .then((databases) => {
                // Filter out global-db
                return databases.filter((db) => {
                    const name = db.name;
                    return name.indexOf('account-db') === 0;
                });
            })
            .then((accountDbs) => {
                return accountDbs.map((item) => {
                    return item.name;
                });
            })
            .then((removes) => {
                console.log('Removing old account data:', removes);               
                return Promise.all(removes.map((name) => {
                    return new Promise((resolve, reject) => {
                        console.log('Removing ', name);
                        const deleteRequest = indexedDB.deleteDatabase(name);
                        deleteRequest.onsuccess = function (event) {
                            console.log('Removed ', name);
                            resolve(name);
                        };

                        deleteRequest.onerror = function (event) {
                            console.log('Unable to remove ', name);
                            reject(event);
                        };

                    });
                }));
            })
            .then((results) => {
                resolve(results);
            })
            .catch(err => {
                reject(err);
            });
        });

        

        
    }
}

export default IndexedDB;
