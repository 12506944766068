'use strict';

import Constants from '../constants';
import ObjectAssign from 'object-assign';
import ParseValidation from '../../../helpers/parse-validation';

const initialState = {
    loading: false,
    success: false,
    error: undefined,
    hasError: {},
    help: {}
};
const reducer = function (state = initialState, action) {

    if (action.type === Constants.LOGOUT) {
        return ObjectAssign({}, state, {
            loading: true
        });
    }

    if (action.type === Constants.LOGOUT_RESPONSE) {

        const validation = ParseValidation(action.response);
        const err = action.err;

        const stateUpdates = {
            success: !action.err,
            loading: false,
            hasError: validation.hasError,
            help: validation.help
        };

        if ( err && !validation.error ) {
            stateUpdates.error = err.message;
        } else {
            stateUpdates.error = validation.error;
        }

        return stateUpdates;
    }

    return state;
};


export default reducer;
