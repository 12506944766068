import ObjectAssign from 'object-assign';
import ParseValidation from './parse-validation';
import Schemas from '../pages/account/reducers/schemas';
import Qs from "qs";
import SerializeError from 'serialize-error';
import produce from 'immer';
import { cloneDeep, get, isEmpty, isEqual, set } from 'lodash';

//need to actual use this in this file...
const initialNetworkStatus = {
    hydrated: false,
    loading: false,
    showFetchFailure: false,
    showSaveSuccess: false,
    error: undefined,
    err: undefined,
    networkError: undefined,
    serverError: undefined,
    hasError: {},
    help: {}
};

const uiPath = ['_ui'];

const processNetworkResponse = (action) => {
    const validation = ParseValidation(action.response);
    let err = action.err;
    if( action.err ) {
        //we do this for storage in Safari's indexedDb
        //otherwise it gets clone error when it gets stored.
        err = SerializeError.serializeError(action.err);
    }

    const networkUpdates = {
        //hydrated: true, //gonna say hydrated no matter what cause that is what was done
        loading: false,
        showFetchFailure: !!action.err,  //may not be needed with new error types but need for now
        err
    };

    if (err) {
        if (err.statusCode === 0) {
            networkUpdates.networkError = err;
        } else {
            networkUpdates.serverError = err;
        }
    } else {
        networkUpdates.networkError = null;
        networkUpdates.serverError = null;
        networkUpdates.hydrated = true;
    }

    if (err && !validation.error) {
        networkUpdates.error = err.message;
    } else {
        networkUpdates.error = validation.error;
        networkUpdates.hasError = validation.hasError;
        networkUpdates.help = validation.help;
    }

    return networkUpdates;
};

const getObjectId = (schema, obj) => {
    
    let id;
    if (schema.id) {
        if (schema.id.indexOf('-') > 0) {
            id = schema.id.split('-').map((idPart) => {
                return obj[idPart];
            }).join('-');
        }
        else {
            return obj[schema.id];
        }
    } 
    else {
        id = obj.id;
    }
    
    return id;
};

const removeIdFromListOnBelongsToObject = (id, entityName, belongsToId, belongsToEntityName, state) => {
    const entityTableName = Schemas.getTableName(entityName);
    const belongsToTableName = Schemas.getTableName(belongsToEntityName);
    const belongsToObject = state.entities[belongsToTableName].byId[belongsToId];
    if (belongsToObject && belongsToObject[entityTableName]) {
        const idx = belongsToObject[entityTableName].indexOf(id);
        if (idx > -1) {
            const array = [...belongsToObject[entityTableName]];
            array.splice(idx, 1);
            state = {
                ...state,
                entities: {
                    ...state.entities,
                    [belongsToTableName]: {
                        ...state.entities[belongsToTableName],
                        byId: {
                            ...state.entities[belongsToTableName].byId,
                            [belongsToId]: {
                                ...state.entities[belongsToTableName].byId[belongsToId],
                                [entityTableName]: array
                            }
                        }
                    }
                }
            };
        }
    }
    return state;
};

const addIdToBelongsToObject = (id, tableName, belongsToId, belongsToTableName, state) => {

    //the belongsToObject might not exist.  In that case we create one but do not add the id.
    //Lack of an id shows that the object is a placeholder.
    let belongsToObject = state.entities[belongsToTableName].byId[belongsToId];
    let change = false;
    if (!belongsToObject) {
        belongsToObject = {
            [tableName]: [id]
        };
        change = true;
    } else if (!belongsToObject[tableName]) {
        belongsToObject = {...belongsToObject, [tableName]: [id]};
        change = true;
    } else if (belongsToObject[tableName].indexOf(id) < 0) {
        belongsToObject = {
            ...belongsToObject,
            [tableName]: [...belongsToObject[tableName], id]
        };
        change = true;
    }
    if (change) {
        //state.entities[belongsToTableName].byId[belongsToId] = {...belongsToObject};
        state = {
            ...state,
            entities: {
                ...state.entities,
                [belongsToTableName]: {
                    ...state.entities[belongsToTableName],
                    byId: {
                        ...state.entities[belongsToTableName].byId,
                        [belongsToId]: belongsToObject
                    }
                }
            }
        };
    }
    return state;
};

const processLocalChange = (state, entityName, response, remove) => {

    const schema = Schemas[entityName];
    const id = getObjectId(schema, response);

    const tableName = Schemas.getTableName(entityName);
    const table = state.entities[tableName];
    let current = table.byId[id];//use the old one if it is there
    const isNew = !current;
    const previous = current;

    if (remove) {
        state = {
            ...state,
            entities: {
                ...state.entities,
                [tableName]: {
                    ...state.entities[tableName],
                    byId: {...state.entities[tableName].byId}
                }
            }
        };
        delete state.entities[tableName].byId[id];
    } else {
        const replacement = {};    
        if (current) {
            schema.localPrimitives.forEach((name) => {
                replacement[name] = current[name]
            });
        }
        
        current = replacement;//make an new one
        schema.primitives.forEach((name) => {
            current[name] = response[name];
        });
        state = {
            ...state,
            entities: {
                ...state.entities,
                [tableName]: {
                    ...state.entities[tableName],
                    byId: {
                        ...state.entities[tableName].byId,
                        [getObjectId(schema, current)]: current
                    }
                }
            }
        };
    }

    if ( schema.queryAdjust ){
        state = schema.queryAdjust(state, isNew, remove);
    }

    schema.belongsTo.forEach((belongsToEntity) => {
        const isBelongsToEntityAnObject = typeof belongsToEntity === 'object';
        const belongsToEntityName = isBelongsToEntityAnObject ? belongsToEntity.entity : belongsToEntity;
        const belongsToTableName = Schemas.getTableName(belongsToEntityName);
        let belongsToId = response[belongsToEntityName + 'Id'];
        if (isBelongsToEntityAnObject) {
            belongsToId = response[belongsToEntity.foreignKey];
        }

        if (belongsToId && remove) {
            state = removeIdFromListOnBelongsToObject(id, entityName, belongsToId, belongsToEntityName, state);
        } else if (belongsToId) {
            //add association if needed
            state = addIdToBelongsToObject(id, tableName, belongsToId, belongsToTableName, state);

            //what if the original object is a join table entry?  we look at the
            //the belongs to see if it joins back to another entity through this table
            const belongsToSchema = Schemas[belongsToEntityName];
            const join = belongsToSchema.belongsToMany.find((bm) => {
                //{ entity: joinedToName, through: entityName }
                return bm.through === entityName;
            });
            if (join) {
                const joinedToEntityName = join.entity;
                const joinedToEntityId = response[joinedToEntityName + 'Id'];
                const joinedToTableName = Schemas.getTableName(joinedToEntityName);
                if (remove) {
                    state = removeIdFromListOnBelongsToObject(id, entityName, joinedToEntityId, joinedToEntityName, state);
                } else {
                    state = addIdToBelongsToObject(joinedToEntityId, joinedToTableName, belongsToId, belongsToTableName, state);
                }
            }
        }
    });

    schema.hasMany.forEach((hasManyEntityName) => {
        //need to keep reference lists if there is one in case current got recreated
        const hasManyTableName = Schemas.getTableName(hasManyEntityName);
        current[hasManyTableName] = (previous && previous[hasManyTableName]) || [];
    });

    schema.belongsToMany.forEach((btm) => {
        const belongsToManyEntityName = btm.entity;
        const belongsToManyTableName = Schemas.getTableName(belongsToManyEntityName);
        current[belongsToManyTableName] = (previous && previous[belongsToManyTableName]) || [];
    });

    return state;
};

// Note: there are cases where we don't fetch all column data at first then later fetch it so we want to forceUpdate to update the entire object again.
// Ex: we have a page list all session notes entries, but we exclude "fields" because it stores all the data for the session notes.  When we click to a session note entry, it won't update because the entry is already existed with the same version
const processServerPayload = (state, entityName, response, forceUpdate = false) => {
    const schema = Schemas[entityName];
    const id = getObjectId(schema, response);
    const tableName = Schemas.getTableName(entityName);
    const table = state.entities[tableName];
    let current = table.byId[id];//use the old one if it is there
    const previous = current;

    if (!current || typeof current.version === 'undefined' || (current.version < response.version) || forceUpdate) {
        //The below may or may not apply anymore.
        //we can get a not !current version when something like a DataSheet comes down with a reference to the
        //ClientSession but only a reference and not the object with the version on it
        const replacement = {};    
        if (current) {
            schema.localPrimitives.forEach((name) => {
                replacement[name] = current[name]
            });
        }
        
        current = replacement;//make an new one        
        schema.primitives.forEach((name) => {
            current[name] = response[name];
        });
    }

    schema.belongsTo.forEach((belongsToEntity) => {
        const isBelongsToEntityAnObject = typeof belongsToEntity === 'object';
        const belongsToEntityName = isBelongsToEntityAnObject ? belongsToEntity.entity : belongsToEntity;
        const belongsToTableName = Schemas.getTableName(belongsToEntityName);
        const belongsToObject = response[belongsToEntityName];
        let belongsToId = response[belongsToEntityName + 'Id'];
        if (isBelongsToEntityAnObject) {
            belongsToId = response[belongsToEntity.foreignKey];
        }
        //for saves it isn't part of the response
        //but in any case if we get one we'll update
        if (belongsToObject) {
            state = processServerPayload(state, belongsToEntityName, belongsToObject);
        }

        if (belongsToId) {
            //add association
            //reader beware when reading the Flat Store.  There can be object references there that
            //are not filled out.  Just sitting there because another cbject came down with a reference to it
            //but not the actual object.  The way to know is that the id will not be there.
            state = addIdToBelongsToObject(id, tableName, belongsToId, belongsToTableName, state);

            //what if the original object is a join table entry?  we look at the
            //the belongs to see if it joins back to another entity through
            //this table
            const belongsToSchema = Schemas[belongsToEntityName];
            const join = belongsToSchema.belongsToMany.find((bm) => {
                //{ entity: joinedToName, through: entityName }
                return bm.through === entityName;
            });
            if (join) {
                const joinedToEntityName = join.entity;
                const joinedToEntityId = response[joinedToEntityName + 'Id'];
                const joinedToTableName = Schemas.getTableName(joinedToEntityName);
                state = addIdToBelongsToObject(joinedToEntityId, joinedToTableName, belongsToId, belongsToTableName, state);
            }
        }
    });

    let arrayChanged = false;
    schema.hasMany.forEach((hasManyEntityName) => {
        //for saves it isn't part of the response
        //const belongsToManyTableName =  Schemas[belongsToManyEntityName].tableName ? Schemas[belongsToManyEntityName].tableName : belongsToManyEntityName + 's';
        const belongsToManyTableName = Schemas.getTableName(hasManyEntityName);
        const incomingArray = response[belongsToManyTableName];
        if (incomingArray) {
            const addIds = [];
            const currentArray = current[belongsToManyTableName] = current[belongsToManyTableName] || [];
            incomingArray.forEach((obj) => {
                state = processServerPayload(state, hasManyEntityName, obj);
                const objId = getObjectId(Schemas[hasManyEntityName], obj);

                if (!currentArray.includes(objId)) {
                    addIds.push(objId);
                }
            });
            if (addIds.length > 0) {
                arrayChanged = true;
                //get new object
                current[belongsToManyTableName] = [...currentArray, ...addIds];
            }
        } else if (previous && previous[belongsToManyTableName]) {
            current[belongsToManyTableName] = previous[belongsToManyTableName];
        } else {
            current[belongsToManyTableName] = [];
        }
    });

    schema.belongsToMany.forEach((btm) => {
        const belongsToManyEntityName = btm.entity;
        const belongsToManyTableName = Schemas.getTableName(belongsToManyEntityName);
        const through = btm.through;
        if (response[belongsToManyTableName]) {
            const addIds = [];
            const incomingArray = response[belongsToManyTableName];
            const currentArray = current[belongsToManyTableName] = current[belongsToManyTableName] || [];
            incomingArray.forEach((obj) => {
                state = processServerPayload(state, belongsToManyEntityName, obj);
                if (!currentArray.includes(obj.id)) {
                    addIds.push(obj.id);
                }
                //check for through going back up, this would be like DataSheet -> PTargets -> DataSheetsPTarget
                //the DataSheetPTarget would be singular (by sequelize) because we have the datasheet and ptarget already
                //specified

                if (obj[through]) {
                    //the other way to do with would be to send the joins with the DataSheet as a hasMany
                    //that might be a little less clever and use lets resources on the query
                    state = processServerPayload(state, through, obj[through]);
                }
                //this object could have come with all the throughs under other types of queries, if and when
                //we should probably check for obj[through + 's']
            });
            if (addIds.length > 0) {
                arrayChanged = true;
                //get new object
                current[belongsToManyTableName] = [...currentArray, ...addIds];
            }
        } else if (previous && previous[belongsToManyTableName]) {
            current[belongsToManyTableName] = previous[belongsToManyTableName];
        } else {
            current[belongsToManyTableName] = [];
        }
        //Could have picked some up from other objects as well.
        //At the top we start by checking to see if there is a current object for this response object.  Is it already
        //there?  Sometimes it is sometimes it isn't.  But sometimes it appears between the top of this method and
        //here.  The reason being that a placeholder was created to hold the belongsToMany ids of other
        //objects.
        //This happens when a many to many comes down like DataSheets <-> DataSheetsPTargets <-> PTargets
        //Even though DataSheets hasn't got here yet, DataSheetsPTargets and PTargets have already finised processing.
        //PTargets knows it relates to DataSheets so it sets up the place holder object in state.entities.
        //PTargets needs to get picked up on DataSheets.PTargets.
        const referenceAlreadyMade = state.entities[tableName] && state.entities[tableName].byId && state.entities[tableName].byId[current.id];
        if ( referenceAlreadyMade && referenceAlreadyMade[belongsToManyTableName]) {
            const set = new Set(referenceAlreadyMade[belongsToManyTableName].concat(current[belongsToManyTableName]));
            current[belongsToManyTableName] = [...set];
        }
    });

    if (arrayChanged && current === state.entities[tableName].byId[id]) {
        //need a new object cause arrays are updated even though the version of this object didn't change
        current = {...current};
    }

    const extras = schema.extras;
    if ( extras) {
        //gotta do this after all child object have passed
        Object.keys(extras).forEach((key) => {
            current = extras[key](state, current, entityName, tableName);
        });
    }

    if (current !== previous) {
        state.entities = {
            ...state.entities,
            [tableName]: {
                ...state.entities[tableName],
                byId: {...state.entities[tableName].byId, [id]: current}
            }
        };
    }
    return state;
};

const processObjectRequest = (action, state, tableName) => {
    const id = action.request.url.match(/[^/]*$/)[0];
    state = {
        ...state,
        entities: {
            ...state.entities,
            [tableName]: {
                ...state.entities[tableName],
                networkStatusById: {
                    ...state.entities[tableName].networkStatusById,
                    [id]: {
                        ...initialNetworkStatus,
                        ...state.entities[tableName].networkStatusById[id],
                        loading: true
                    }
                }
            }
        }
    };
    return state;
};

const processObjectResponse = (action, state, entityName, tableName, forceUpdate = false) => {

    const id = action.request.url.match(/[^/]*$/)[0];
    const networkUpdates = processNetworkResponse(action);
    if (!networkUpdates.err && !networkUpdates.error) {
        state = processServerPayload(state, entityName, action.response, forceUpdate);
    }

    state = {
        ...state,
        entities: {
            ...state.entities,
            [tableName]: {
                ...state.entities[tableName],
                networkStatusById: {
                    ...state.entities[tableName].networkStatusById,
                    [id]: networkUpdates
                }
            }
        }
    };

    return state;
};

const processQueryRequest = (action, state, tableName) => {
    const query = Qs.stringify(action.request.query);
    const ids = state.collections[tableName].idsByQuery[query] ?
        state.collections[tableName].idsByQuery[query] : [];

    state = {
        ...state,
        collections: {
            ...state.collections,
            [tableName]: {
                ...state.collections[tableName],
                idsByQuery: {
                    ...state.collections[tableName].idsByQuery,
                    [query]: ids
                },
                networkStatusByQuery: {
                    ...state.collections[tableName].networkStatusByQuery,
                    [query]: {
                        ...initialNetworkStatus, ...state.collections[tableName].networkStatusByQuery[query],
                        loading: true
                    }
                }
            }
        }
    };
    return state;
};

const processQueryResponse = (action, state, entityName, tableName) => {
    const query = Qs.stringify(action.request.query);
    const networkUpdates = processNetworkResponse(action);
    state = {
        ...state,
        collections: {
            ...state.collections,
            [tableName]: {
                ...state.collections[tableName],
                networkStatusByQuery: {
                    ...state.collections[tableName].networkStatusByQuery,
                    [query]: {
                        ...state.collections[tableName].networkStatusByQuery[query],
                        ...networkUpdates
                    }
                }
            }
        }
    };

    if (!networkUpdates.err && !networkUpdates.error) {
        const response = action.response;
        const ids = [];
        response.data.forEach((pl) => {
            state = processServerPayload(state, entityName, pl);
            const schema = Schemas[entityName];
            const id = getObjectId(schema, pl);            
            ids.push(id);
        });

        state.collections[tableName] = {
            ...state.collections[tableName],
            pagesByQuery: {
                ...state.collections[tableName].pagesByQuery,
                [query]: response.pages
            },
            itemsByQuery: {
                ...state.collections[tableName].itemsByQuery,
                [query]: response.items
            },
            idsByQuery: {
                ...state.collections[tableName].idsByQuery,
                [query]: ids
            }
        };
    } else if ( !state.collections[tableName].networkStatusByQuery[query].hydrated ) {
        if ( Schemas[entityName].offlineQuery ) {
            state = Schemas[entityName].offlineQuery(state, query);
            state = {
                ...state,
                collections: {
                    ...state.collections,
                    [tableName]: {
                        ...state.collections[tableName],
                        networkStatusByQuery: {
                            ...state.collections[tableName].networkStatusByQuery,
                            [query]: {
                                ...state.collections[tableName].networkStatusByQuery[query],
                                hydrated: true
                            }
                        }
                    }
                }
            };

        }
    }

    return state;
};

const processSaveObject = (action, state, tableName) => {
    const id = action.request.data.id;
    state = {
        ...state,
        entities: {
            ...state.entities,
            [tableName]: {
                ...state.entities[tableName],
                networkStatusById: {
                    ...state.entities[tableName].networkStatusById,
                    [id]: {
                        ...initialNetworkStatus,
                        ...state.entities[tableName].networkStatusById[id],
                        loading: true
                    }
                }
            }
        }
    };
    return state;
};

const processSaveObjectResponse = (action, state, entityName) => {
    //here we assume the local store has already been updated
    //we should do something is the request fails...
    const tableName = Schemas.getTableName(entityName);
    const obj = action.request.data;
    const schema = Schemas[entityName];
    const id = getObjectId(schema, obj);
    const networkUpdates = processNetworkResponse(action);
    state = {
        ...state,
        entities: {
            ...state.entities,
            [tableName]: {
                ...state.entities[tableName],
                networkStatusById: {
                    ...state.entities[tableName].networkStatusById,
                    [id]: networkUpdates
                }
            }
        }
    };

    return state;
};

const processPostObject = (action, state, entityName) => {
    const tableName = Schemas.getTableName(entityName);
    const schema = Schemas[entityName];
    const id = getObjectId(schema, action.request.data);

    state = {
        ...state,
        entities: {
            ...state.entities,
            [tableName]: {
                ...state.entities[tableName],
                networkStatusById: {
                    ...state.entities[tableName].networkStatusById,
                    [id]: {
                        ...initialNetworkStatus,
                        ...state.entities[tableName].networkStatusById[id],
                        loading: true
                    }
                }
            }
        }
    };

    return state;
};

const processPostObjectResponse = (action, state, entityName) => {
    const tableName = Schemas.getTableName(entityName);
    const schema = Schemas[entityName];
    const id = getObjectId(schema, action.request.data);
    const networkUpdates = processNetworkResponse(action);
    if (!networkUpdates.err && !networkUpdates.error) {        
        state = processServerPayload(state, entityName, action.response);        
    }

    state = {
        ...state,
        entities: {
            ...state.entities,
            [tableName]: {
                ...state.entities[tableName],
                networkStatusById: {
                    ...state.entities[tableName].networkStatusById,
                    [id]: networkUpdates
                }
            }
        }
    };

    return state;
};

const processPatch = (action, name, state, tableName) => {
    const changes = action.request.data.changes;
    changes.forEach((change) => {
        const id = change[name].id;
        state = {
            ...state,
            entities: {
                ...state.entities,
                [tableName]: {
                    ...state.entities[tableName],
                    networkStatusById: {
                        ...state.entities[tableName].networkStatusById,
                        [id]: {
                            ...initialNetworkStatus,
                            ...state.entities[tableName].networkStatusById[id],
                            loading: true
                        }
                    }
                }
            }
        };
    });
    return state;
};

const processPatchResponse = (action, name, state, entityName) => {
    const tableName = Schemas.getTableName(entityName);
    const networkUpdates = processNetworkResponse(action);
    if (!networkUpdates.err && !networkUpdates.error) {
        const objects = action.response;
        objects.forEach((object) => {
            state = processServerPayload(state, entityName, object);
            state = {
                ...state,
                entities: {
                    ...state.entities,
                    [tableName]: {
                        ...state.entities[tableName],
                        networkStatusById: {
                            ...state.entities[tableName].networkStatusById,
                            [object.id]: networkUpdates
                        }
                    }
                }
            };
        });
    } else {
        const changes = action.request.data.changes;
        changes.forEach((change) => {
            const id = change[name].id;
            state = {
                ...state,
                entities: {
                    ...state.entities,
                    [tableName]: {
                        ...state.entities[tableName],
                        networkStatusById: {
                            ...state.entities[tableName].networkStatusById,
                            [id]: networkUpdates
                        }
                    }
                }
            };
        });
    }

    return state;
};

const processDeleteObjectResponse = (action, state, tableName) => {

    const id = action.request.url.match(/[^/]*$/)[0];
    const networkStatus = processNetworkResponse(action);
    const networkStatusById = {...state.entities[tableName].networkStatusById};
    if (!networkStatus.err && !networkStatus.error) {
        delete networkStatusById[id];
    } else {
        networkStatusById[id] = networkStatus;
    }

    state = {
        ...state,
        entities: {
            ...state.entities,
            [tableName]: {
                ...state.entities[tableName], networkStatusById
            }
        }
    };

    return state;
};

const showPBxConfirmIntervalModal = (name, action, state) => {

    state = { ...state,
        prompts: { ...state.prompts,
            pbx: { ...state.prompts.pbx,
                [name]: { ...state.prompts.pbx[name],
                    confirmInterval: [ ...state.prompts.pbx[name].confirmInterval,
                        {
                            dataSheetId: action.dataSheetId,
                            trialId: action.trialId,
                            intervalEnded: action.intervalEnded
                        }
                    ]
                }
            }
        },
        promptQueue: [ ...state.promptQueue,
            action.dataSheetId
        ]
    };

    return state;
};

const hidePBxConfirmIntervalModal = (name, action, state) => {

    const dataSheetId = action.dataSheetId;
    const confirmInterval = [...state.prompts.pbx[name].confirmInterval];
    const idx = confirmInterval.findIndex((md) => {
        return md.trialId === action.trialId && md.dataSheetId === dataSheetId;
    });
    confirmInterval.splice(idx, 1);

    const promptQueue = [...state.promptQueue];
    promptQueue.splice(promptQueue.indexOf(dataSheetId), 1);

    state = { ...state,
        prompts: { ...state.prompts,
            pbx: { ...state.prompts.pbx,
                [name]: { ...state.prompts.pbx[name], confirmInterval
                }
            }
        },
        promptQueue
    };
    return state;
};

const showPbxModifyTrialModal = (name, action, state) => {
    state = { ...state,
        prompts: { ...state.prompts,
            pbx: { ...state.prompts.pbx,
                [name]: { ...state.prompts.pbx[name],
                    modifyTrial: [ ...state.prompts.pbx[name].modifyTrial,
                        {
                            dataSheetId: action.dataSheetId,
                            trialId: action.trialId
                        }
                    ]
                }
            }
        },
        promptQueue: [ ...state.promptQueue,
            action.dataSheetId
        ]
    };

    return state;
};

const hidePbxModifyTrialModal = (name, action, state) => {

    const dataSheetId = action.dataSheetId;
    const modifyTrial = [...state.prompts.pbx[name].modifyTrial];
    const idx = modifyTrial.findIndex((md) => {
        return md.trialId === action.trialId && md.dataSheetId === dataSheetId;
    });
    modifyTrial.splice(idx, 1);

    const promptQueue = [...state.promptQueue];
    promptQueue.splice(promptQueue.indexOf(dataSheetId), 1);

    state = { ...state,
        prompts: { ...state.prompts,
            pbx: { ...state.prompts.pbx,
                [name]: { ...state.prompts.pbx[name], modifyTrial
                }
            }
        },
        promptQueue
    };
    return state;
};

const updatePbxResumeInterval = (name, action, state) => {
    state = {
        ...state,
        prompts: {
            ...state.prompts,
            pbx: {
                ...state.prompts.pbx,
                [name]: {
                    ...state.prompts.pbx[name],
                    resumeInterval: [
                        ...state.prompts.pbx[name].resumeInterval,
                        action.dataSheetId,
                    ],
                },
            },
        },
    };
    return state;
};

const resetPbxResumeInterval = (name, action, state) => {
    state = {
        ...state,
        prompts: {
            ...state.prompts,
            pbx: {
                ...state.prompts.pbx,
                [name]: {
                    ...state.prompts.pbx[name],
                    resumeInterval: [],
                },
            },
        },
    };
    return state;
};

const resultsMaker = function (GET_RESULTS, GET_RESULTS_RESPONSE, RESET) {

    if (!GET_RESULTS || !GET_RESULTS_RESPONSE) {
        throw Error('Missing Action Type');
    }

    const initialState = {
        hydrated: false,
        loading: false,
        error: undefined,
        err: undefined,
        data: [],
        pages: {},
        items: {}
    };

    return function (state = initialState, action) {

        if (action.type === GET_RESULTS) {
            return ObjectAssign({}, state, {
                loading: true
            });
        }

        if (action.type === RESET) {
            return { ...initialState };
        }

        if (action.type === GET_RESULTS_RESPONSE) {

            const validation = ParseValidation(action.response);

            const stateUpdates = ObjectAssign({}, state, {
                hydrated: true,//not if this should be here or down under !validation.error
                loading: false,
                showFetchFailure: !!action.err
            });

            stateUpdates.err = action.err;
            if (action.err && !validation.error) {
                stateUpdates.error = action.err.message;
            } else {
                stateUpdates.hydrated = true;
                stateUpdates.error = validation.error;
                if (!validation.error) {
                    stateUpdates.data = action.response.data;
                    stateUpdates.pages = action.response.pages;
                    stateUpdates.items = action.response.items;
                }
            }

            return ObjectAssign({}, state, stateUpdates);
        }

        return state;
    };
};

//this one you get some results and change a few of them and "patch" them back.
//best for things that have large result sets where you can't expect to push up all changes
//and get all back.  More you push up your changes and reload your view which may or may
//include what you've changed ( say you are paging through results, you can change them
// you go and then save)
const resultsPatchMaker = function (GET_RESULTS, GET_RESULTS_RESPONSE, CHANGE_RESULTS,
    CHANGE_RESULTS_RESPONSE, CHANGE_AND_RELOAD, CHANGE_AND_RELOAD_COMPLETE, RESET) {

    if (!GET_RESULTS || !GET_RESULTS_RESPONSE || !CHANGE_RESULTS || !CHANGE_RESULTS_RESPONSE
        || !CHANGE_AND_RELOAD || !CHANGE_AND_RELOAD_COMPLETE) {
        throw Error('Missing Action Type');
    }

    const initialState = {
        hydrated: false,
        loading: false,
        changing_reloading: false,
        error: undefined,
        hasError: {},//a way to point out errors with individual items of data
        help: {},
        data: [],
        pages: {},
        items: {}
    };

    return function (state = initialState, action) {

        if (action.type === RESET) {
            return {...initialState}
        }

        if (action.type === CHANGE_AND_RELOAD) {
            return ObjectAssign({}, state, {
                changing_reloading: true
            });
        }

        if (action.type === CHANGE_AND_RELOAD_COMPLETE) {
            return ObjectAssign({}, state, {
                changing_reloading: false
            });
        }

        if (action.type === CHANGE_RESULTS) {
            return ObjectAssign({}, state, {
                loading: true
            });
        }

        if (action.type === CHANGE_RESULTS_RESPONSE) {

            const validation = ParseValidation(action.response);

            const stateUpdates = {
                loading: false,
                showSaveSuccess: !action.err
            };

            if (action.err && !validation.error) {
                stateUpdates.error = action.err.message;
            } else {
                stateUpdates.error = validation.error;
                stateUpdates.hasError = validation.hasError;
                stateUpdates.help = validation.help;
            }

            return ObjectAssign({}, state, stateUpdates);
        }

        if (action.type === GET_RESULTS) {
            return ObjectAssign({}, state, {
                loading: true
            });
        }

        if (action.type === GET_RESULTS_RESPONSE) {

            const validation = ParseValidation(action.response);

            const stateUpdates = ObjectAssign({}, state, {
                loading: false,
                showFetchFailure: !!action.err
            });

            if (action.err && !validation.error) {
                stateUpdates.error = action.err.message;
            } else {
                stateUpdates.hydrated = true;
                stateUpdates.error = validation.error;
                if (!validation.error) {
                    stateUpdates.hydrated = true;
                    stateUpdates.data = action.response.data;
                    stateUpdates.pages = action.response.pages;
                    stateUpdates.items = action.response.items;
                }
            }

            return ObjectAssign({}, state, stateUpdates);
        }

        return state;
    };

};

//this one you get all results and change a few of them and "patch" them back
//and have the results update im place.
const resultsPatchInPlaceMaker = function (GET_RESULTS, GET_RESULTS_RESPONSE, PATCH_RESULTS, PATCH_RESULTS_RESPONSE, RESET) {

    if (!GET_RESULTS || !GET_RESULTS_RESPONSE || !PATCH_RESULTS || !PATCH_RESULTS_RESPONSE) {
        throw Error('Missing Action Type');
    }

    const initialState = {
        hydrated: false,
        loading: false,
        patching: false,
        error: undefined,
        hasError: {},//a way to point out errors with individual items of data
        help: {},
        data: [],
        pages: {},
        items: {},
        deletedIds: []//since we may not deal with the entire set we can't know who has been delete on the last save
    };

    return function (state = initialState, action) {

        if (action.type === RESET) {
            return { ...initialState, hasError: {}, help: {}, data: [], pages: {}, items: {}, deletedIds: [] };
        }

        if (action.type === PATCH_RESULTS) {
            return ObjectAssign({}, state, {
                patching: true
            });
        }

        if (action.type === PATCH_RESULTS_RESPONSE) {

            const validation = ParseValidation(action.response);

            const stateUpdates = {
                patching: false,
                showSaveSuccess: !action.err
            };

            if (action.err && !validation.error) {
                stateUpdates.error = action.err.message;
            } else {
                stateUpdates.error = validation.error;
                stateUpdates.hasError = validation.hasError;
                stateUpdates.help = validation.help;
                if (!validation.error) {
                    const updates = action.response.data.slice(0).filter((update) => {
                        return !update.__deleted;
                    });
                    const deleted = action.response.data.slice(0).filter((update) => {
                        return !!update.__deleted;
                    });
                    const currents = state.data;

                    for (let i = 0; i < currents.length; i++) {
                        const current = currents[i];
                        const idx = updates.findIndex((u) => {
                            return u.id === current.id;
                        });
                        if (idx > -1) {
                            const update = updates[idx];
                            currents[i] = update;
                            updates.splice(idx, 1);
                        }
                    }

                    const deletedIds = deleted.map((d) => {
                        const idx = currents.findIndex((c) => {
                            return c.id === d.id;
                        });
                        currents.splice(idx, 1);
                        return d.id;
                    });


                    stateUpdates.data = currents.concat(updates);
                    stateUpdates.deletedIds = deletedIds;
                }
            }

            return ObjectAssign({}, state, stateUpdates);
        }

        if (action.type === GET_RESULTS) {
            return ObjectAssign({}, state, {
                loading: true
            });
        }

        if (action.type === GET_RESULTS_RESPONSE) {

            const validation = ParseValidation(action.response);

            const stateUpdates = ObjectAssign({}, state, {
                loading: false,
                showFetchFailure: !!action.err
            });

            if (action.err) {
                stateUpdates.error = action.err.message;
            } else {
                stateUpdates.hydrated = true;
                stateUpdates.error = validation.error;
                if (!validation.error) {
                    stateUpdates.hydrated = true;
                    stateUpdates.data = action.response.data;
                    stateUpdates.pages = action.response.pages;
                    stateUpdates.items = action.response.items;
                }
            }

            return ObjectAssign({}, state, stateUpdates);
        }

        return state;
    };

};

//this one you get all results at once, no paging, you put them all at once too
//best for things that non pagable numbers where you want to see them all at once
//and they don't come with paging
const resultsPutMaker = function (GET_RESULTS, GET_RESULTS_RESPONSE, SAVE_RESULTS, SAVE_RESULTS_RESPONSE, RESET) {

    if (!GET_RESULTS || !GET_RESULTS_RESPONSE || !SAVE_RESULTS || !SAVE_RESULTS_RESPONSE) {
        throw Error('Missing Action Type');
    }

    const initialState = {
        hydrated: false,
        loading: false,
        error: undefined,
        hasError: {},//a way to point out errors with individual items of data
        help: {},
        data: []
    };

    return function (state = initialState, action) {

        if (action.type === RESET) {
            return { ...initialState, data: [], help: {} };
        }

        if (action.type === GET_RESULTS) {
            return ObjectAssign({}, initialState, {
                loading: true
            });
        }

        if (action.type === GET_RESULTS_RESPONSE) {

            const validation = ParseValidation(action.response);

            const stateUpdates = ObjectAssign({}, state, {
                hydrated: true,//not if this should be here or down under !validation.error
                loading: false,
                showFetchFailure: !!action.err
            });

            if (action.err) {
                stateUpdates.error = action.err.message;
            } else {
                stateUpdates.hydrated = true;
                stateUpdates.error = validation.error;
                if (!validation.error) {
                    stateUpdates.data = action.response;
                }
            }

            return ObjectAssign({}, state, stateUpdates);
        }

        if (action.type === SAVE_RESULTS) {
            return ObjectAssign({}, state, {
                loading: true
            });
        }

        if (action.type === SAVE_RESULTS_RESPONSE) {

            const validation = ParseValidation(action.response);

            const stateUpdates = {
                loading: false,
                showSaveSuccess: !action.err
            };

            if (action.err && !validation.error) {
                stateUpdates.error = action.err.message;
            } else {
                stateUpdates.error = validation.error;
                stateUpdates.hasError = validation.hasError;
                stateUpdates.help = validation.help;
                if (!validation.error) {
                    stateUpdates.data = action.response;
                }
            }

            return ObjectAssign({}, state, stateUpdates);
        }

        return state;
    };
};

//changing assignment of data = action.response to data = action.response.data
//this works with page results where you say limit=all
const resultsPutAllMaker = function (GET_RESULTS, GET_RESULTS_RESPONSE, SAVE_RESULTS, SAVE_RESULTS_RESPONSE, keepHydratedOnGet = false, RESET) {

    if (!GET_RESULTS || !GET_RESULTS_RESPONSE || !SAVE_RESULTS || !SAVE_RESULTS_RESPONSE) {
        throw Error('Missing Action Type');
    }

    const initialState = {
        hydrated: false,
        loading: false,
        error: undefined,
        hasError: {},//a way to point out errors with individual items of data
        help: {},
        data: []
    };

    return function (state = initialState, action) {

        if (action.type === RESET) {
            return { ...initialState, data: [], help: {} };
        }

        if (action.type === GET_RESULTS) {
            //return ObjectAssign({}, initialState, {
            //    loading: true
            //});

            if (keepHydratedOnGet || action.keepHydrated) {
                return ObjectAssign({}, state, {
                    hydrated: state.hydrated,
                    loading: true
                });
            }

            return ObjectAssign({}, initialState, {
                hydrated: false,
                loading: true
            });
        }

        if (action.type === GET_RESULTS_RESPONSE) {

            const validation = ParseValidation(action.response);

            const stateUpdates = ObjectAssign({}, state, {
                hydrated: true,//not if this should be here or down under !validation.error
                loading: false,
                showFetchFailure: !!action.err
            });

            if (action.err) {
                stateUpdates.error = action.err.message;
            } else {
                stateUpdates.hydrated = true;
                stateUpdates.error = validation.error;
                if (!validation.error) {
                    stateUpdates.data = action.response.data;
                    stateUpdates.pages = action.response.pages;
                    stateUpdates.items = action.response.items;
                }
            }

            return ObjectAssign({}, state, stateUpdates);
        }

        if (action.type === SAVE_RESULTS) {
            return ObjectAssign({}, state, {
                loading: true
            });
        }

        if (action.type === SAVE_RESULTS_RESPONSE) {

            const validation = ParseValidation(action.response);

            const stateUpdates = {
                loading: false,
                showSaveSuccess: !action.err
            };

            if (action.err && !validation.error) {
                stateUpdates.error = action.err.message;
            } else {
                stateUpdates.error = validation.error;
                stateUpdates.hasError = validation.hasError;
                stateUpdates.help = validation.help;
                if (!validation.error) {
                    stateUpdates.data = action.response.data;
                    stateUpdates.pages = action.response.pages;
                    stateUpdates.items = action.response.items;
                }
            }

            return ObjectAssign({}, state, stateUpdates);
        }

        return state;
    };
};

/*
This is for where you want to get a bunch of paged results and keep them in the same place.  Like say you have a bunch of
oaccounts on a page and you want to show all teh trainings for each.  You would have to have a separate redurect for
each one.  This reducer will take whatever you throw at it and organizae them by id.
 */
const resultsesMaker = function (GET_RESULTS, GET_RESULTS_RESPONSE) {

    if (!GET_RESULTS || !GET_RESULTS_RESPONSE) {
        throw Error('Missing Action Type');
    }

    const individualState = {
        hydrated: false,
        loading: false,
        showFetchFailure: false,
        error: undefined,
        data: [],
        pages: {},
        items: {}
    };

    const initialState = {};
    return function (state = initialState, action) {

        const id = action.id;
        if (action.type === GET_RESULTS) {

            if (!state[id]) {
                state[id] = ObjectAssign({}, individualState, {
                    loading: true
                });
            }

            return ObjectAssign({}, state);
        }

        if (action.type === GET_RESULTS_RESPONSE) {

            const validation = ParseValidation(action.response);

            const individual = ObjectAssign({}, state[id], {
                loading: false,
                showFetchFailure: !!action.err
            });

            if (action.err) {
                individual.error = action.err.message;
            } else {
                individual.hydrated = true;
                individual.error = validation.error;
                if (!validation.error) {
                    individual.data = action.response.data;
                    individual.pages = action.response.pages;
                    individual.items = action.response.items;
                }
            }

            return ObjectAssign({}, state, {[id]: individual});
        }

        return state;
    };
};


const progressMaker = function (GET_PROGRESS, GET_PROGRESS_RESPONSE) {

    if (!GET_PROGRESS || !GET_PROGRESS_RESPONSE) {
        throw Error('Missing Action Type');
    }

    const initialState = {
        hydrated: false,
        loading: false,
        error: undefined,
        err: undefined,
        networkError: undefined,
        serverError: undefined,
        progress: {part: 0, total: 1}
    };

    return function (state = initialState, action) {

        if (action.type === GET_PROGRESS) {
            return ObjectAssign({}, state, {
                hydrated: false,
                loading: true
            });
        }

        if (action.type === GET_PROGRESS_RESPONSE) {

            const validation = ParseValidation(action.response);
            const err = action.err;


            const stateUpdates = {
                loading: false,
                err
            };

            if (err) {
                if (err.statusCode === 0) {
                    stateUpdates.networkError = err;
                } else {
                    stateUpdates.serverError = err;
                }
            } else {
                stateUpdates.networkError = null;
                stateUpdates.serverError = null;
            }

            if (err && !validation.error) {
                stateUpdates.error = err.message;
            } else {
                const response = action.response;
                if (!validation.error) {
                    stateUpdates.loading = false;
                    stateUpdates.progress = response;
                }

                stateUpdates.error = validation.error;
                stateUpdates.hasError = validation.hasError;
                stateUpdates.help = validation.help;
            }

            return ObjectAssign({}, state, stateUpdates);
        }

        return state;
    };

};

/*
if you are getting multiple progresses and want to organize them by id
 */
const progressesMaker = function (GET_PROGRESS, GET_PROGRESS_RESPONSE) {

    if (!GET_PROGRESS || !GET_PROGRESS_RESPONSE) {
        throw Error('Missing Action Type');
    }

    const individualState = {
        progress: {}
    };
    //todo this one should probably replace progress.js
    const initialState = {};

    return function (state = initialState, action) {

        const id = action.id;

        if (action.type === GET_PROGRESS) {
            if (!state[id]) {
                state[id] = {};
            }

            state[id] = ObjectAssign({}, individualState, {
                hydrated: false,
                loading: true
            });

            return ObjectAssign({}, state);
        }

        if (action.type === GET_PROGRESS_RESPONSE) {

            const validation = ParseValidation(action.response);

            const individualProgress = {
                loading: false,
                showFetchFailure: !!action.err
            };
            if (action.err && !validation.error) {
                individualProgress.error = action.err.message;
            } else {
                individualProgress.error = validation.error;
                if (!validation.error) {
                    individualProgress.hydrated = true;
                    individualProgress.progress = action.response;
                }
            }

            const stateUpdates = ObjectAssign({}, state, {[id]: individualProgress});

            const ids = Object.keys(stateUpdates);

            stateUpdates.loading = ids.reduce((acc, currentId) => {

                return acc || state[currentId].loading;
            }, false);


            return stateUpdates;
        }

        return state;

    };

};

const detailsMaker = function (GET_DETAILS, GET_DETAILS_RESPONSE, SAVE_DETAILS,
                               SAVE_DETAILS_RESPONSE, HIDE_DETAILS_SAVE_SUCCESS, RESET_DETAILS, Initial_Properties, keepHydratedOnGet) {

    //dehydrateOnGet is for clients that want to keep previous state on reloads
    if (!GET_DETAILS || !GET_DETAILS_RESPONSE || !SAVE_DETAILS
        || !SAVE_DETAILS_RESPONSE || !HIDE_DETAILS_SAVE_SUCCESS
        || !RESET_DETAILS || !Initial_Properties) {
        throw Error('Missing Action Type');
    }

    const initialState = {
        hydrated: false,
        loading: false,
        showFetchFailure: false,
        showSaveSuccess: false,
        error: undefined,
        err: undefined,
        networkError: undefined,
        serverError: undefined,
        hasError: {},
        help: {}
    };

    const properties = Object.keys(Initial_Properties);

    ObjectAssign(initialState, Initial_Properties);

    return function (state = initialState, action) {

        if (action.type === RESET_DETAILS) {
            return ObjectAssign({}, initialState);
        }

        if (action.type === GET_DETAILS) {
            //you can keep hydrated per reducer creation or per API call.
            if (keepHydratedOnGet || action.keepHydrated) {
                return ObjectAssign({}, state, {
                    hydrated: state.hydrated,
                    loading: true
                });

            }

            return ObjectAssign({}, initialState, {
                hydrated: false,
                loading: true
            });
        }

        if (action.type === GET_DETAILS_RESPONSE) {
            const validation = ParseValidation(action.response);
            const err = action.err;

            const stateUpdates = {
                hydrated: true, //gonna say hydrated no matter what cause that is what was done
                loading: false,
                showFetchFailure: !!action.err,  //may not be needed with new error types but need for now
                err
            };

            if (err) {
                if (err.statusCode === 0) {
                    stateUpdates.networkError = err;
                } else {
                    stateUpdates.serverError = err;
                }
            } else {
                stateUpdates.networkError = null;
                stateUpdates.serverError = null;
            }

            if (err && !validation.error) {
                stateUpdates.error = err.message;
            } else {
                if (!validation.error) {
                    properties.forEach((k) => {
                        stateUpdates[k] = action.response[k];
                    });
                }

                stateUpdates.error = validation.error;
                stateUpdates.hasError = validation.hasError;
                stateUpdates.help = validation.help;
            }

            return ObjectAssign({}, state, stateUpdates);
        }

        if (action.type === SAVE_DETAILS) {
            return ObjectAssign({}, state, {
                loading: true
            });
        }

        if (action.type === SAVE_DETAILS_RESPONSE) {

            const validation = ParseValidation(action.response);
            const err = action.err;

            const stateUpdates = {
                loading: false,
                showSaveSuccess: !action.err
            };

            if (err) {
                if (err.statusCode === 0) {
                    stateUpdates.networkError = err;
                } else {
                    stateUpdates.serverError = err;
                }
            } else {
                stateUpdates.networkError = null;
                stateUpdates.serverError = null;
            }

            stateUpdates.err = action.err;
            if (action.err && !validation.error) {
                stateUpdates.error = action.err.message;
            } else {
                stateUpdates.error = validation.error;
                stateUpdates.hasError = validation.hasError;
                stateUpdates.help = validation.help;
                if (!validation.error) {
                    properties.forEach((k) => {
                        stateUpdates[k] = action.response[k];
                    });
                }
            }

            return ObjectAssign({}, state, stateUpdates);
        }

        if (action.type === HIDE_DETAILS_SAVE_SUCCESS) {
            return ObjectAssign({}, state, {
                showSaveSuccess: false
            });
        }

        return state;
    };
};

/*
This is a version of validation I was considering for validation that happens in the store.  You validate on thing
at a time with CHECK_LOCAL or you validate all with CHECK_LOCAL_ALL.  The problem with the current design of forms is
that they don't keep modified state in the Store and this will change the store which will override the modified state.
Probably will chuck this if the flat store way works better
 */
const detailsWCheckMaker = function (GET_DETAILS, GET_DETAILS_RESPONSE, SAVE_DETAILS, SAVE_DETAILS_RESPONSE,
                                     HIDE_DETAILS_SAVE_SUCCESS, RESET_DETAILS, CHECK_LOCAL, CHECK_LOCAL_ALL, Initial_Properties, Check_Functions, keepHydratedOnGet) {

    //dehydrateOnGet is for clients that want to keep previous state on reloads
    if (!GET_DETAILS || !GET_DETAILS_RESPONSE || !SAVE_DETAILS
        || !SAVE_DETAILS_RESPONSE || !HIDE_DETAILS_SAVE_SUCCESS
        || !RESET_DETAILS || !CHECK_LOCAL || !CHECK_LOCAL_ALL
        || !Initial_Properties || !Check_Functions) {
        throw Error('Missing Action Type');
    }

    const initialState = {
        hydrated: false,
        loading: false,
        showFetchFailure: false,
        showSaveSuccess: false,
        error: undefined,
        err: undefined,
        networkError: undefined,
        serverError: undefined,
        hasError: {},
        help: {}
    };

    const properties = Object.keys(Initial_Properties);

    ObjectAssign(initialState, Initial_Properties);

    return function (state = initialState, action) {

        if (action.type === RESET_DETAILS) {
            return ObjectAssign({}, initialState);
        }

        if (action.type === CHECK_LOCAL) {
            if (Check_Functions[action.key]) {
                const updates = Check_Functions[action.key](state, action.key);
                const stateUpdates = ObjectAssign({},
                    {
                        hasError: ObjectAssign({}, state.hasError, updates.hasError),
                        help: ObjectAssign({}, state.help, updates.help)
                    });
                return ObjectAssign({}, state, stateUpdates);
            }

            return ObjectAssign({}, state, {error: 'Check function not defined for ' + action.key});
        }

        if (action.type === CHECK_LOCAL_ALL) {

        }

        if (action.type === GET_DETAILS) {
            //you can keep hydrated per reducer creation or per API call.
            if (keepHydratedOnGet || action.keepHydrated) {
                return ObjectAssign({}, state, {
                    hydrated: state.hydrated,
                    loading: true
                });

            }

            return ObjectAssign({}, initialState, {
                hydrated: false,
                loading: true
            });
        }

        if (action.type === GET_DETAILS_RESPONSE) {

            const validation = ParseValidation(action.response);
            const err = action.err;

            const stateUpdates = {
                hydrated: true, //gonna say hydrated no matter what cause that is what was done
                loading: false,
                showFetchFailure: !!action.err,  //may not be needed with new error types but need for now
                err
            };

            if (err) {
                if (err.statusCode === 0) {
                    stateUpdates.networkError = err;
                } else {
                    stateUpdates.serverError = err;
                }
            } else {
                stateUpdates.networkError = null;
                stateUpdates.serverError = null;
            }

            if (err && !validation.error) {
                stateUpdates.error = err.message;
            } else {
                if (!validation.error) {
                    properties.forEach((k) => {
                        stateUpdates[k] = action.response[k];
                    });
                }

                stateUpdates.error = validation.error;
                stateUpdates.hasError = validation.hasError;
                stateUpdates.help = validation.help;
            }

            return ObjectAssign({}, state, stateUpdates);
        }

        if (action.type === SAVE_DETAILS) {
            return ObjectAssign({}, state, {
                loading: true
            });
        }

        if (action.type === SAVE_DETAILS_RESPONSE) {

            const validation = ParseValidation(action.response);
            const err = action.err;

            const stateUpdates = {
                loading: false,
                showSaveSuccess: !action.err
            };

            if (err) {
                if (err.statusCode === 0) {
                    stateUpdates.networkError = err;
                } else {
                    stateUpdates.serverError = err;
                }
            } else {
                stateUpdates.networkError = null;
                stateUpdates.serverError = null;
            }

            stateUpdates.err = action.err;
            if (action.err && !validation.error) {
                stateUpdates.error = action.err.message;
            } else {
                stateUpdates.error = validation.error;
                stateUpdates.hasError = validation.hasError;
                stateUpdates.help = validation.help;
                if (!validation.error) {
                    properties.forEach((k) => {
                        stateUpdates[k] = action.response[k];
                    });
                }
            }

            return ObjectAssign({}, state, stateUpdates);
        }

        if (action.type === HIDE_DETAILS_SAVE_SUCCESS) {
            return ObjectAssign({}, state, {
                showSaveSuccess: false
            });
        }

        return state;
    };
};

const deleteMaker = function (GET_DETAILS_RESPONSE, DELETE, DELETE_RESPONSE) {

    if (!GET_DETAILS_RESPONSE || !DELETE || !DELETE_RESPONSE) {
        throw Error('Missing Action Type');
    }

    const initialState = {
        loading: false,
        error: undefined
    };

    return function (state = initialState, action) {
        if (action.type === GET_DETAILS_RESPONSE) {
            return ObjectAssign({}, initialState);
        }

        if (action.type === DELETE) {
            return ObjectAssign({}, state, {
                loading: true
            });
        }

        if (action.type === DELETE_RESPONSE) {

            const validation = ParseValidation(action.response);
            const err = action.err;

            const stateUpdates = {
                loading: false,
                showSaveSuccess: !action.err
            };

            if (err) {
                if (err.statusCode === 0) {
                    stateUpdates.networkError = err;
                } else {
                    stateUpdates.serverError = err;
                }
            } else {
                stateUpdates.networkError = null;
                stateUpdates.serverError = null;
            }

            stateUpdates.err = action.err;
            if (action.err && !validation.error) {
                stateUpdates.error = action.err.message;
            } else {
                stateUpdates.error = validation.error;
                stateUpdates.hasError = validation.hasError;
                stateUpdates.help = validation.help;
            }

            return ObjectAssign({}, state, stateUpdates);
        }

        return state;
    };
};

const createNewMaker = function (CREATE_NEW, CREATE_NEW_RESPONSE, SHOW_CREATE_NEW, HIDE_CREATE_NEW, props, RESET) {


    if (!CREATE_NEW || !CREATE_NEW_RESPONSE || !SHOW_CREATE_NEW || !HIDE_CREATE_NEW) {
        throw Error('Missing Action Type');
    }

    const initialState = ObjectAssign({
        show: false,
        loading: false,
        error: undefined,
        hasError: {},
        help: {}
    }, props);

    return function (state = initialState, action) {

        if ( action.type === RESET ) {
            return { ...initialState };
        }

        if (action.type === CREATE_NEW) {
            const stateUpdates = ObjectAssign({}, state, {
                loading: true
            });

            Object.keys(props).forEach((k) => {
                stateUpdates[k] = action.request.data[k];
            });

            return stateUpdates;
        }

        if (action.type === CREATE_NEW_RESPONSE) {
            const validation = ParseValidation(action.response);
            const stateUpdates = {
                loading: false,
                error: validation.error,
                hasError: validation.hasError,
                help: validation.help
            };

            if (!validation.error) {
                Object.keys(props).forEach((k) => {
                    stateUpdates[k] = props[k];
                });
            }

            return ObjectAssign({}, state, stateUpdates);
        }

        if (action.type === SHOW_CREATE_NEW) {
            return ObjectAssign({}, state, {
                show: true
            });
        }

        if (action.type === HIDE_CREATE_NEW) {
            return ObjectAssign({}, state, {
                show: false
            });
        }

        return state;
    };

};

const modifiedDetailsMaker = function (
    SET_MODIFIED_DETAILS,
    UPDATE_MODIFIED_DETAILS,
    RESET_MODIFIED_DETAILS,
    detailsInitialState,
    customizedValidateFields,
) {
    if (!SET_MODIFIED_DETAILS ||
        !UPDATE_MODIFIED_DETAILS ||
        !RESET_MODIFIED_DETAILS ||
        !detailsInitialState
    ) {
        throw Error('Missing Action Type');
    }

    const initialState = {
        ...detailsInitialState,
        _ui: {
            help: {},
            hasError: {},
            requiredFields: [], // an array of objects with format { name, type, path }
            needSave: false,
            changePaths: {}, // should use Set() but we need to enableMapSet() in immer not sure if it worths the trouble so let use object for now
        },
    };

    let validateFields;
    if (typeof customizedValidateFields === 'function') {
        validateFields = customizedValidateFields;
    } else {
        validateFields = (state) => {
            const help = {};
            const hasError = {};
            const { requiredFields } = state._ui;
        
            requiredFields.forEach(item => {
                const { name, type, path } = item;
                const value = get(state, path);
                switch (type) {
                    default: // string
                        if (isEmpty(value)) {
                            help[name] = 'required';
                            hasError[name] = true;
                        }
                        break;
                }
            });
        
            return { help, hasError };
        };
    }

    return function (state = initialState, action) {
        switch (action.type) {
            case RESET_MODIFIED_DETAILS: {
                return { ...initialState };
            }
    
            case SET_MODIFIED_DETAILS: {
                const newState = { ...state, ...cloneDeep(action.data) };

                return produce(newState, draftState => {
                    const { help, hasError } = validateFields(draftState);
                    draftState._ui.help = help;
                    draftState._ui.hasError = hasError;
    
                    // Reset need save
                    draftState._ui.needSave = false;
                    draftState._ui.changePaths = {};
                });
            }
    
            case UPDATE_MODIFIED_DETAILS: {
                const { details, path, value } = action;
    
                const nextState = produce(state, draftState => {
                    set(draftState, path, value);
    
                    const { help, hasError } = validateFields(draftState);
                    set(draftState, [...uiPath, 'help'], help);
                    set(draftState, [...uiPath, 'hasError'], hasError);
    
                    // We'll keep track of change paths so we will know if we need to save without
                    // having to do a deep compare for current state and draft state
                    const lastValue = get(details, path, '');
                    const changePaths = { ...get(state, [...uiPath, 'changePaths']) };
                    const pathString = path.join(',');
    
                    // If last value != value and path is not in change paths then we'll add to it
                    if (!isEqual(lastValue, value)) {
                        changePaths[pathString] = true;;    // it won't add existing path
                    }
                    else {
                        delete changePaths[pathString];
                    }
                    
                    const needSave = Object.keys(changePaths).length > 0;
                    set(draftState, [...uiPath, 'needSave'], needSave);
                    set(draftState, [...uiPath, 'changePaths'], changePaths);
                });
    
                return nextState;
            }
    
            default:
                return state;
        }
    };
};

const modifiedResultsMaker = function (
    SET_MODIFIED_RESULTS,
    UPDATE_MODIFIED_RESULTS,
    RESET_MODIFIED_RESULTS,
    resultsInitialState,
    validateFields,
) {
    if (!SET_MODIFIED_RESULTS ||
        !UPDATE_MODIFIED_RESULTS ||
        !RESET_MODIFIED_RESULTS ||
        !resultsInitialState ||
        !validateFields
    ) {
        throw Error('Missing Action Type');
    }

    const initialState = {
        ...resultsInitialState,
        _ui: {
            help: {},
            hasError: {},
            requiredFields: [], // an array of objects with format { name, type, path, fields }
            needSave: false,
            changePaths: {}, // should use Set() but we need to enableMapSet() in immer not sure if it worths the trouble so let use object for now
        },
    };

    return function (state = initialState, action) {
        switch (action.type) {
            case RESET_MODIFIED_RESULTS: {
                return { ...initialState };
            }
    
            case SET_MODIFIED_RESULTS: {
                const newState = { ...state, ...cloneDeep(action.data) };

                return produce(newState, draftState => {
                    const { requiredFields } = draftState._ui;
                    const { help, hasError } = validateFields(draftState, requiredFields, {}, {});
                    draftState._ui.help = help;
                    draftState._ui.hasError = hasError;
    
                    // Reset need save
                    draftState._ui.needSave = false;
                    draftState._ui.changePaths = {};
                });
            }
    
            case UPDATE_MODIFIED_RESULTS: {
                const { details, path, value } = action;
    
                const nextState = produce(state, draftState => {
                    set(draftState, path, value);
    
                    const { requiredFields } = draftState._ui;
                    const { help, hasError } = validateFields(draftState, requiredFields, {}, {});
                    set(draftState, [...uiPath, 'help'], help);
                    set(draftState, [...uiPath, 'hasError'], hasError);
    
                    // We'll keep track of change paths so we will know if we need to save without
                    // having to do a deep compare for current state and draft state
                    const lastValue = get(details, path, '');
                    const changePaths = { ...get(state, [...uiPath, 'changePaths']) };
                    const pathString = path.join(',');
    
                    // If last value != value and path is not in change paths then we'll add to it
                    if (!isEqual(lastValue, value)) {
                        changePaths[pathString] = true;;    // it won't add existing path
                    }
                    else {
                        delete changePaths[pathString];
                    }
                    
                    const needSave = Object.keys(changePaths).length > 0;
                    set(draftState, [...uiPath, 'needSave'], needSave);
                    set(draftState, [...uiPath, 'changePaths'], changePaths);
                });
    
                return nextState;
            }
    
            default:
                return state;
        }
    };
};

export {
    processNetworkResponse,
    processLocalChange,
    processServerPayload,
    processObjectRequest,
    processObjectResponse,
    processQueryRequest,
    processQueryResponse,
    processSaveObject,
    processSaveObjectResponse,
    processDeleteObjectResponse,
    processPatch,
    processPatchResponse,
    processPostObject,
    processPostObjectResponse,
    showPBxConfirmIntervalModal,
    hidePBxConfirmIntervalModal,
    updatePbxResumeInterval,
    resetPbxResumeInterval,
    showPbxModifyTrialModal,
    hidePbxModifyTrialModal,
    initialNetworkStatus,
    createNewMaker,
    resultsMaker,
    resultsesMaker,
    progressesMaker,
    progressMaker,
    detailsMaker,
    deleteMaker,
    resultsPatchMaker,
    resultsPatchInPlaceMaker,
    resultsPutMaker,
    resultsPutAllMaker,
    modifiedDetailsMaker,
    modifiedResultsMaker,
};

export default {
    processNetworkResponse,
    processLocalChange,
    processServerPayload,
    processObjectRequest,
    processObjectResponse,
    processQueryRequest,
    processQueryResponse,
    processSaveObject,
    processSaveObjectResponse,
    processDeleteObjectResponse,
    processPatch,
    processPatchResponse,
    processPostObject,
    processPostObjectResponse,
    showPBxConfirmIntervalModal,
    hidePBxConfirmIntervalModal,
    updatePbxResumeInterval,
    resetPbxResumeInterval,
    showPbxModifyTrialModal,
    hidePbxModifyTrialModal,
    initialNetworkStatus,
    createNewMaker,
    resultsMaker,
    resultsesMaker,
    progressesMaker,
    progressMaker,
    detailsMaker,
    deleteMaker,
    resultsPatchMaker,
    resultsPatchInPlaceMaker,
    resultsPutMaker,
    resultsPutAllMaker
};
