'use strict';
import FluxConstant from 'flux-constant';


export default FluxConstant.set([
    'GET_PROGRESS',
    'GET_PROGRESS_RESPONSE',
    'GET_RESULTS',
    'GET_RESULTS_RESPONSE',
    'SHOW_EMAIL',
    'HIDE_EMAIL'
]);
