/* global window, location */
'use strict';

import ApiActions from '../../actions/api';
import Constants from './constants';
import Store from './store';

class Actions {

    static setAllNetworkStatus(status, store = Store) {
        store.dispatch({
            type: Constants.SET_ALL_NETWORK_STATUS,
            status
        });
    }

    static setupdatesPending(updatesPending) {
        Store.dispatch({
            type: Constants.SET_UPDATES_PENDING,
            updatesPending
        });
    }

    static setOfflineAnnouncement(show) {
        Store.dispatch({
            type: Constants.SHOW_OFFLINE_ANNOUNCEMENT,
            show
        });
    }

    static setLoggedOffAnnouncement(show) {
        Store.dispatch({
            type: Constants.SHOW_LOGGED_OFF_ANNOUNCEMENT,
            show
        });
    }

    static setNetworkActivity(activity) {
        Store.dispatch({
            type: Constants.SET_NETWORK_ACTIVITY,
            activity
        });
    }

    static setIndexedDbError(errorThrown) {
        Store.dispatch({
            type: Constants.SET_IDB_ERROR,
            errorThrown
        });
    }

    static setIndexedDbStatus(status) {
        Store.dispatch({
            type: Constants.SET_IDB_STATUS,
            available: status
        });
    }

    static setApiStatus(status) {
        Store.dispatch({
            type: Constants.SET_API_STATUS,
            available: status
        });
    }

    static setNetworkStatus(status) {
        Store.dispatch({
            type: Constants.SET_NETWORK_STATUS,
            available: status
        });
    }

    static setBrowserNetworkStatus(status) {
        Store.dispatch({
            type: Constants.SET_BROWSER_REPORTED_NETWORK_STATUS,
            available: status
        });
    }

    static getMyAccount(store = Store) {

        ApiActions.get(
            '/api/accounts/my',
            undefined,
            store,
            Constants.GET_MY_ACCOUNT,
            Constants.GET_MY_ACCOUNT_RESPONSE
        );
    }

    static getMyAdmin(store = Store) {

        ApiActions.get(
            '/api/oadmins/my',
            undefined,
            store,
            Constants.GET_MY_ADMIN,
            Constants.GET_MY_ADMIN_RESPONSE
        );
    }

    static getMyClients(store = Store, query, cb) {

        ApiActions.get(
            '/api/clients/my',
            query,
            store,
            Constants.GET_MY_CLIENTS,
            Constants.GET_MY_CLIENTS_RESPONSE,
            cb
        );
    }

    static getMyOrganization(cb) {

        ApiActions.get(
            '/api/organizations/my',
            '',
            Store,
            Constants.GET_MY_ORGANIZATION,
            Constants.GET_MY_ORGANIZATION_RESPONSE,
            cb
        );
    }

    static switchAccount(type, type_id, organization_id, store = Store, path) {

        ApiActions.post(
            '/api/login/switch',
            { type, type_id },
            store,
            Constants.SWITCH_ACCOUNTS,
            Constants.SWITCH_ACCOUNTS_RESPONSE,
            ( err ) => {

                if ( !err ){

                    if ( type === 'oadmin') {
                        location = path ? path : `/admin/organizations/${organization_id}`;
                    }
                    else if ( type === 'oaccount') {
                        location = path ? path : '/account/clients';
                    }
                }
            }
        );
    }

    static logout(store) {
        ApiActions.delete(
            '/api/logout',
            undefined,
            store,
            Constants.LOGOUT,
            Constants.LOGOUT_RESPONSE,
            (err) => {
                if ( !err ) {
                    document.location = '/login/logout';
                }
            }
        );
    }

    static getAllFeatureFlagsByOrganization(store = Store, data = {}, cb) {        
        ApiActions.get(
            `/api/organizations/feature-flags`,
            data,
            store,
            Constants.GET_ALL_FEATURE_FLAGS_BY_ORGANIZATION,
            Constants.GET_ALL_FEATURE_FLAGS_BY_ORGANIZATION_RESPONSE,
            cb
        );
    }
}

export default Actions;
