import Constants from '../constants';


const initialState = {
    indexedDbErrorThrown: null,
    indexedDbAvailable: false,
    indexedDbError: false,
    networkAvailable: true,
    apiAvailable: true,
    apiError: false,
    serverError: false,
    error401: false,
    error403: false,
    commit: '',
    release: '',
    appVersion: '',
    btViewRefresh: false,
    adminViewRefresh: false,
    browserReportedNetworkAvailable: true,
    offlineDataUploading: false,
    networkActivity: false,
    showAnnouncement: false,
    showLoggedOffAnnouncement: false,
    updatesPending: 0
};
const Storage = function (state = initialState, action) {

    if (action.type === Constants.SET_ALL_NETWORK_STATUS ) {
        const status = action.status;
        state = {...state,
            updatesPending: status.updatesPending,
            networkActivity: status.networkActivity,
            offlineDataUploading: status.offlineDataUploading,
            browserReportedNetworkAvailable: status.browserReportedNetworkAvailable,
            apiAvailable: status.apiAvailable,
            apiError: status.apiError,
            serverError: status.serverError,
            error401: status.error401,
            error403: status.error403,
            commit: status.commit,
            release: status.release,
            appVersion: status.appVersion,
            btViewRefresh: status.btViewRefresh,
            adminViewRefresh: status.adminViewRefresh,
            networkAvailable: status.networkAvailable
        };
        return state;
    }

    if (action.type === Constants.SET_UPDATES_PENDING) {
        state = {...state, updatesPending: action.updatesPending};
        return state;
    }

    if (action.type === Constants.SHOW_OFFLINE_ANNOUNCEMENT) {
        state = {...state, showAnnouncement: action.show};
        return state;
    }

    if (action.type === Constants.SHOW_LOGGED_OFF_ANNOUNCEMENT) {
        state = {...state, showLoggedOffAnnouncement: action.show};
        return state;
    }

    if (action.type === Constants.UPLOAD_OFFLINE_DATA) {
        state = {...state, offlineDataUploading: true};
        return state;
    }

    if (action.type === Constants.SET_NETWORK_ACTIVITY) {
        state = {...state, networkActivity: action.activity};
        return state;
    }

    if (action.type === Constants.UPLOAD_OFFLINE_DATA_RESPONSE) {
        state = {...state, offlineDataUploading: false};
        return state;
    }

    if (action.type === Constants.SET_IDB_ERROR) {
        state = {...state, indexedDbErrorThrown: action.errorThrown, indexedDbError: !!action.errorThrown};
        return state;
    }

    if (action.type === Constants.SET_IDB_STATUS) {
        state = { ...state, indexedDbAvailable: action.available };
        return state;
    }

    if (action.type === Constants.SET_NETWORK_STATUS) {
        state = { ...state, networkAvailable: action.available };
        return state;
    }

    if (action.type === Constants.SET_BROWSER_REPORTED_NETWORK_STATUS) {
        state = { ...state, browserReportedNetworkAvailable: action.available };
        return state;
    }

    if (action.type === Constants.SET_API_STATUS) {
        state = { ...state, apiAvailable: action.available };
        return state;
    }

    return state;
};


export default Storage;
