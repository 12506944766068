'use strict';

import Constants from '../constants';
import ObjectAssign from 'object-assign';


const initialState = {
    hydrated: false,
    loading: false,
    error: undefined,
    progresses: {}
};
const reducer = function (state = initialState, action) {

    if (action.type === Constants.GET_PROGRESS) {
        return ObjectAssign({}, state, {
            hydrated: false,
            loading: true
        });
    }

    if (action.type === Constants.GET_PROGRESS_RESPONSE) {
        const individualProgress = {};
        if ( !action.err) {

            individualProgress[action.request.query.id] = action.response;
            return ObjectAssign({}, state, {
                hydrated: true,
                loading: false,
                progresses: ObjectAssign(state.progresses, individualProgress)
            });
        }

        individualProgress[action.request.query.id] = { error: action.err.message };
        return ObjectAssign({}, state, {
            loading: false,
            progresses: ObjectAssign(state.progresses, individualProgress),
            error: action.err.message
        });

    }

    return state;
};


export default reducer;
