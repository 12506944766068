'use strict';
import FluxConstant from 'flux-constant';

export default FluxConstant.set([
    'SET_ALL_NETWORK_STATUS',
    'SET_UPDATES_PENDING',
    'SHOW_OFFLINE_ANNOUNCEMENT',
    'SHOW_LOGGED_OFF_ANNOUNCEMENT',
    'SET_IDB_ERROR',
    'SET_IDB_STATUS',
    'SET_NETWORK_STATUS',
    'SET_NETWORK_ACTIVITY',
    'SET_BROWSER_REPORTED_NETWORK_STATUS',
    'SET_API_STATUS',
    'GET_MY_ACCOUNT',
    'GET_MY_ACCOUNT_RESPONSE',
    'SAVE_MY_ACCOUNT',
    'SAVE_MY_ACCOUNT_RESPONSE',
    'HIDE_MY_ACCOUNT_SAVE_SUCCESS',
    'RESET_MY_ACCOUNT',
    'GET_MY_ADMIN',
    'GET_MY_ADMIN_RESPONSE',
    'GET_MY_ORGANIZATION',
    'GET_MY_ORGANIZATION_RESPONSE',
    'SWITCH_ACCOUNTS',
    'SWITCH_ACCOUNTS_RESPONSE',
    'GET_MY_CLIENTS',
    'GET_MY_CLIENTS_RESPONSE',
    'UPLOAD_OFFLINE_DATA',
    'UPLOAD_OFFLINE_DATA_RESPONSE',
    'LOGOUT',
    'LOGOUT_RESPONSE',
    'GET_ALL_FEATURE_FLAGS_BY_ORGANIZATION',
    'GET_ALL_FEATURE_FLAGS_BY_ORGANIZATION_RESPONSE',
]);
