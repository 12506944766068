

import Constants from '../constants';
import ObjectAssign from 'object-assign';
import ParseValidation from '../../../helpers/parse-validation.js';


const initialState = {
    hydrated: false,
    loading: false,
    showFetchFailure: false,
    showSaveSuccess: false,
    error: undefined,
    hasError: {},
    help: {},
    id: undefined,
    name: ''
    //logo?: ''
};
const reducer = function (state = initialState, action) {

    if (action.type === Constants.GET_MY_ORGANIZATION) {
        return ObjectAssign({}, initialState, {
            hydrated: false,
            loading: true
        });
    }

    if (action.type === Constants.GET_MY_ORGANIZATION_RESPONSE) {

        const validation = ParseValidation(action.response);
        const err = action.err;

        const stateUpdates = {
            loading: false,
            hydrated: true,
            showFetchFailure : !!action.err, //may not be used?
            err
        };

        if (err) {
            if (err.statusCode === 0) {
                stateUpdates.networkError = err;
            }
            else {
                stateUpdates.serverError = err;
            }
        }
        else {
            stateUpdates.networkError = null;
            stateUpdates.serverError = null;
        }

        if ( err && !validation.error ) {
            stateUpdates.error = err.message || 'Unknown Error!';   // can err.message be empty and be falsy?
        }
        else {
            if ( !validation.error) {
                stateUpdates.id = action.response.id;
                stateUpdates.name = action.response.name;
            }

            stateUpdates.error = validation.error;
        }

        return ObjectAssign({}, state, stateUpdates);
    }

    return state;
};


export default reducer;
