/* global document */
'use strict';

import JsonFetch from '../helpers/json-fetch';
import ObjectAssign from 'object-assign';
import Log from './../helpers/log';
import StackTrace from 'stacktrace-js';
import { v1 as uuidv1 } from 'uuid';
import IndexedDB from "../pages/account/data/indexedDB";

const createResponseHandler = (request, store, typeReq, typeRes, callback, passThru) => {

    //need to trap the original cookie and original stack
    const origin = new Error('Request Origin');//could call StackTrace.get()
    const cookies = document.cookie;
    return (err, body, response) => {

        //put err.statusCode !== for when we return 404 via Boom.js
        if (err && err.statusCode !== 0) {
            if (!body || body.statusCode !== 404) {
                //by the way the crumb cookie can change from the time this request is made, 1 value,
                //to the response, another value.  So JsonFetch can read one cookie but when the
                //request goes up the new cookie is set.  So since these are in a promise I think
                //we get a tick
                const badPassword = body.statusCode === 400 && request.method === 'POST' && request.url === '/api/login';
                if (!badPassword) {
                //All of this is rather verbose tracking of where a given errored out request started from.
                    StackTrace.fromError(origin).then((sfs) => {
                        Log('Error', {
                            err,
                            message: err.message,
                            response: body,
                            sfs,
                            request,
                            'request-cookies': cookies, 'request-headers': err.headers
                        }, undefined, undefined,true);
                    }, (err) => {
                        Log('Error', {
                            err,
                            message: err.message, response: body,
                            'request-cookies': cookies, 'request-headers': err.headers
                        }, undefined, undefined, true);
                    });
                }
            }
        }

        processStatus(err, response);
        store.dispatch(ObjectAssign({
            type: typeRes,
            err,
            response: body,
            request//added state here so I could know about who
        }, passThru));

        if (callback) {
            callback(err, body);
        }
    };
};

const sendQueuedResponse = (request, store, typeReq, typeRes, callback, passThru) => {

    const err = {
        statusCode: 0,
        statusText: '',
        showFetchFailure: true,
        queuing: true
    };

    store.dispatch(ObjectAssign({
        type: typeRes,
        err,
        response: null,
        request//added state here so I could know about who
    }, passThru));

    if (callback) {
        callback(err);
    }
};


class Actions {
    static get(url, query, store, typeReq, typeRes, callback, passThru, redirectOn401, keepHydrated, offlineSupport, requestOptions) {

        const request = {method: 'GET', url, query, redirectOn401, ...requestOptions};
        return this.makeRequest(request, store, typeReq, typeRes, callback, passThru, keepHydrated, offlineSupport);
    }

    static head(url, query, store, typeReq, typeRes, callback, passThru, redirectOn401, offlineSupport) {

        const request = {method: 'HEAD', url, query, redirectOn401};
        return this.makeRequest(request, store, typeReq, typeRes, callback, passThru, false, offlineSupport);
    }

    static put(url, data, store, typeReq, typeRes, callback, passThru, redirectOn401, offlineSupport) {

        const request = {method: 'PUT', url, data, redirectOn401};
        return this.makeRequest(request, store, typeReq, typeRes, callback, passThru, false, offlineSupport);
    }

    static post(url, data, store, typeReq, typeRes, callback, passThru, redirectOn401, offlineSupport, requestOptions) {

        const request = {method: 'POST', url, data, redirectOn401, ...requestOptions};
        return this.makeRequest(request, store, typeReq, typeRes, callback, passThru, false, offlineSupport);
    }

    static patch(url, data, store, typeReq, typeRes, callback, passThru, redirectOn401, offlineSupport) {

        const request = {method: 'PATCH', url, data, redirectOn401};
        return this.makeRequest(request, store, typeReq, typeRes, callback, passThru, false, offlineSupport);
    }

    static delete(url, query, store, typeReq, typeRes, callback, passThru, redirectOn401, offlineSupport) {

        const request = {method: 'DELETE', url, query, redirectOn401};
        return this.makeRequest(request, store, typeReq, typeRes, callback, passThru, false, offlineSupport);
    }

    static addOnlineListener(f) {
        onlineStatusListeners.push(f);
        //go ahead and call it once to give them
        //current status
        notifyOnlineStatus();
    }

    static makeRequest(request, store, typeReq, typeRes, callback, passThru = {}, keepHydrated, offlineSupport) {

        store.dispatch(ObjectAssign({
            type: typeReq,
            request,
            keepHydrated
        }, passThru));

        const method = request.method;
        if (offlineSupport) {
            if (method === 'HEAD' || method === 'GET') {
                addToGetQueue({requestArgs: arguments});
                return;
            } else {
                addToChangeQueue({requestArgs: arguments});
                return;
            }
        }

        //standard json fetch
        const cb = createResponseHandler(request, store, typeReq, typeRes, callback, passThru);
        return JsonFetch(request, cb);
    }

    static isChangeQueueBusy() {
        return changeQueue.length > 0;
    }

    static cancelGetQueue() {
        Object.keys(getQueueInFlight).forEach((key) => {
            getQueueInFlight[key].xhr.abort();
            delete getQueueInFlight[key];
        });
        Object.keys(getQueue).forEach((key) => {
            delete getQueue[key];
        });
        notifyOnlineStatus();
    }

    static frontLoadChangeQueue(queue, Store, typeReq, typeRes) {
        //only call during page load
        //static makeRequest(request, store, typeReq, typeRes, callback, passThru = {}, keepHydrated, offlineSupport) {
        //addToChangeQueue({requestArgs: arguments});
        queue.forEach((item) => {
            const request = { requestArgs: [item.request, Store, typeReq, typeRes, null, null, null, null], id: item.id };
            changeQueue.push(request);
        });
        //kick it off
        processChangeQueue();
    }
}

//we duplicate state here and in the store for others to know
//we keep state here independently so we don't have to send on every call
//this could be running in SSR so check for navigator
let browserReportedNetworkAvailable = (typeof navigator !== 'undefined') && navigator.onLine;
let networkAvailable = true;
let apiAvailable = true;
let networkActivity = false;
let apiError = false;
let serverError = false;
let error403 = false;
let error401 = false;
let commit = '';
let release = '';
let appVersion = '';
let btViewRefresh = true;
let adminViewRefresh = true;

const onlineStatusListeners = [];
const notifyOnlineStatus = () => {
    networkActivity = changeQueueInFlight || Object.keys(getQueueInFlight).length > 0;
    onlineStatusListeners.forEach( f => {
        f({
            browserReportedNetworkAvailable,
            networkAvailable,
            apiAvailable,
            apiError,
            serverError,
            commit,
            release,
            networkActivity,
            error401,
            error403,
            updatesPending: changeQueue.length,
            appVersion,
            btViewRefresh,
            adminViewRefresh,
        });
    });
}

//this could be running in SSR so check for navigator
if ( typeof window !== 'undefined') {
    window.addEventListener('offline', function (e) {
        browserReportedNetworkAvailable = false;
        notifyOnlineStatus();
    });

    window.addEventListener('online', function (e) {
        browserReportedNetworkAvailable = true;
        notifyOnlineStatus();
    });
}


const processStatus = (err, response) => {
    if (response && response.headers) {
        const headers = response.headers;
        commit = headers['x-pc-commit'];
        release = headers['x-pc-release'];
        appVersion = headers['x-pc-app-version'];
        btViewRefresh = headers['x-pc-bt-view-refresh'] === 'true';
        adminViewRefresh = headers['x-pc-admin-view-refresh'] === 'true';
    }

    if (err && err.statusCode === 403) {
        error403 = true;
        notifyOnlineStatus();
    } else {
        error403 = false;
        notifyOnlineStatus();
    }

    if (err && err.statusCode === 401) {
        error401 = true;
    } else {
        error401 = false;
    }

    if (err && err.connectionError && networkAvailable) {
        networkAvailable = false;
        apiAvailable = false;
        notifyOnlineStatus();
    } else if ((!err || !err.connectionError) && !networkAvailable) {
        networkAvailable = true;
        apiAvailable = true;
        notifyOnlineStatus();
    }

    if ( err && err.statusCode === 400) {
        apiError = true;
    } else {
        apiError = false;
    }
    if ( err && err.statusCode >= 500) {
        serverError = true;
    } else {
        serverError = false;
    }
};

const changeQueue = [];

const addToChangeQueue = (request) => {
    const id = uuidv1();
    request.id = id;
    changeQueue.push(request);
    IndexedDB.persistChangeQueueItem(id, request.requestArgs[0]).then((event) => {
        processChangeQueue(request);
    }, (event) => {
        processChangeQueue(request);
    });
};

let processingChangeQueue = false;
let changeQueueRetryCount = 0;
let changeQueueInFlight = false;

const processChangeQueue = (firstTimeRequest) => {
    //We run immediately if changeQueue is empty
    //If not we send a queued error and remove the callback.
    //If you want to know about further updates on this object then listen in onComponentDidUpdate for an object reference change.
    if (changeQueue.length === 0) {
        console.log('change queue empty');
        processGetQueue();//see if anything is sitting in here
        return;
    }

    if (processingChangeQueue) {
        //console.log('blocked processing change queue');
        if (firstTimeRequest) {//this means its the first try
            //we set the callback to null
            firstTimeRequest.requestArgs[5] = null;
            sendQueuedResponse(...firstTimeRequest.requestArgs);
        }
        return;
    }
    //console.log('proceeding processing change queue');
    processingChangeQueue = true;
    const request = changeQueue[0];
    const standardCallback = createResponseHandler(...request.requestArgs);

    const callback = (err, body) => {

        let remove;
        if (!err) {
            changeQueueRetryCount = 0;
            changeQueue.shift();
            remove = IndexedDB.unpersistChangeQueueItem(request.id);
            if ( changeQueue.length === 0){
                changeQueueInFlight = false;
                notifyOnlineStatus();
            }
        } else {
            if (changeQueueRetryCount < 10) {
                changeQueueRetryCount = changeQueueRetryCount + 1;
            }
            remove = Promise.resolve();
            changeQueueInFlight = false;
            notifyOnlineStatus();
        }
        remove.finally(() => {

            standardCallback(err, body);
            if (changeQueueRetryCount === 0) {
                processingChangeQueue = false;
                processChangeQueue();
            } else {
                const waitTime = 100 + (10 * Math.pow(2, changeQueueRetryCount));
                //console.log('retry count', changeQueueRetryCount, waitTime/1000);
                setTimeout(() => {
                    processingChangeQueue = false;
                    processChangeQueue();
                }, waitTime);
            }
        });
    };
    changeQueueInFlight = true;
    notifyOnlineStatus();
    JsonFetch(request.requestArgs[0], callback);

};

const getQueue = {};
const getQueueInFlight = {};
let getQueueRetryCount = 0;
let getRetryTimer = null;
const fireGetRetryTimer = (err) => {

    if (getRetryTimer) {
        //console.log('Get retry timer already running');
        return;
    }
    let waitTime = 200;
    if (err) {
        if (getQueueRetryCount < 12) {
            getQueueRetryCount = getQueueRetryCount + 1;
        }
        waitTime = waitTime + (10 * Math.pow(2, getQueueRetryCount));
    } else {
        getQueueRetryCount = 0;
    }
    //console.log('get retry count', getQueueRetryCount, waitTime/1000);
    getRetryTimer = setTimeout(() => {
        getRetryTimer = null;
        //console.log('Get retry timer done, processing queue again');
        processGetQueue();
    }, waitTime);

};

const processGetQueue = (id) => {
    //We run immediately if changeQueue is empty
    //If not we send a queued error and remove the callback.
    //If you want to know about further updates on this object then listen in onComponentDidUpdate for an object reference change.
    if (changeQueue.length > 0) {
        if (id) {
            //this is the first send and it has already fired network loading dispatch
            //so we need to fail it and get the loading status back to not loading
            const request = getQueue[id];
            //we set the callback to null
            request.requestArgs[5] = null;
            sendQueuedResponse(...request.requestArgs);
        }
        return;
    }
    const keys = Object.keys(getQueue);
    if (keys.length === 0) {
        return;
    }

    keys.forEach((id) => {
        const request = getQueue[id];
        delete getQueue[id];
        const standardCallback = createResponseHandler(...request.requestArgs);

        const callback = (err, body) => {

            if (!getQueueInFlight[id]) {
                //we must have aborted it
                return;
            }

            delete getQueueInFlight[id];
            if ( Object.keys(getQueueInFlight).length === 0) {
                notifyOnlineStatus();
            }
            if (err) {
                //put it back on getQueue
                getQueue[id] = request;
            }
            standardCallback(err, body);
            fireGetRetryTimer(err);
        };
        const xhr = JsonFetch(request.requestArgs[0], callback);
        getQueueInFlight[id] = {request, xhr};
        if ( Object.keys(getQueueInFlight).length === 1) {
            notifyOnlineStatus();
        }
    });
};


const addToGetQueue = (request) => {
    const id = uuidv1();
    getQueue[id] = request;
    processGetQueue(id);
};

export default Actions;
