'use strict';

import Constants from '../constants';
import ObjectAssign from 'object-assign';
import ParseValidation from '../../../../../helpers/parse-validation';


const initialState = {
    hydrated: false,
    loading: false,
    error: undefined,
    data: [],
    pages: {},
    items: {}
};
const reducer = function (state = initialState, action) {

    if (action.type === Constants.RESET_RESULTS) {
        return { ...initialState };
    }

    if (action.type === Constants.GET_RESULTS) {
        return ObjectAssign({}, state, {
            hydrated: false,
            loading: true
        });
    }

    if (action.type === Constants.GET_RESULTS_RESPONSE) {
        if ( !action.err) {
            return ObjectAssign({}, state, {
                hydrated: true,
                loading: false,
                data: action.response.data,
                pages: action.response.pages,
                items: action.response.items
            });
        }

        return ObjectAssign({}, state, {
            hydrated: false,
            loading: false,
            error: action.err
        });

    }

    if (action.type === Constants.PATCH_DETAILS) {

        //this is kind of a bolt on.  Not sure how else to do it for now
        const id = action.id;
        const data = state.data.slice(0);

        const video = state.data.find((v) => {
            return v.id === id;
        });
        if ( video ) {
            video._patching = true;
            video._patchingError = undefined;
            //return ObjectAssign({}, state, { data } );//that should cause an update
        }

        return ObjectAssign({}, state, { data });
    }

    if (action.type === Constants.PATCH_DETAILS_RESPONSE) {
        //just assume the call makes it to the server
        const validation = ParseValidation(action.response);
        const id = action.id;
        const data = state.data.slice(0);
        const video = state.data.find((v) => {
            return v.id === id;
        });
        if ( video ) {

            //return ObjectAssign({}, state, { data } );//that should cause an update
            video._patching = false;

            if (action.err) {
                video._patchingError = action.err;
            } else if (validation.error) {
                video._patchingError = validation.error;
            } else {
                video.farthest_view = action.response.farthest_view;
                video.last_pause = action.response.last_pause;
                delete video._patching;
                delete video._patchingError;
            }
        }

        return ObjectAssign({}, state, { data} );//that should cause an update


    }

    if (action.type === Constants.PATCH_DETAILS) {

        console.log('is this used?');
        //we just assume the call makes it to the server
        const farthest = action.request.data.farthest_view;

    }

    if (action.type === Constants.RESET) {
        return ObjectAssign({}, initialState);
    }

    return state;
};


export default reducer;
