'use strict';
import Constants from '../constants';
import ObjectAssign from 'object-assign';


const initialState = {
    hydrated: false,
    loading: false,
    error: undefined,
    data: [],
    pages: {},
    items: {}
};
const reducer = function (state = initialState, action) {

    if (action.type === Constants.GET_RESULTS) {
        return ObjectAssign({}, state, {
            hydrated: false,
            loading: true
        });
    }

    if (action.type === Constants.GET_RESULTS_RESPONSE) {
        if ( !action.err) {
            return ObjectAssign({}, state, {
                hydrated: true,
                loading: false,
                data: action.response.data,
                pages: action.response.pages,
                items: action.response.items
            });
        }
        else {
            return ObjectAssign({}, state, {
                hydrated: false,
                loading: false,
                error: action.err
            });
        }
    }

    if (action.type === Constants.RESET) {
        return ObjectAssign({}, initialState);
    }

    return state;
};


export default reducer;
