

import Constants from '../constants';
import { detailsMaker as ResultsMaker } from '../../../helpers/reducer';

const reducer = ResultsMaker(Constants.GET_MY_ACCOUNT,  Constants.GET_MY_ACCOUNT_RESPONSE,
    Constants.SAVE_MY_ACCOUNT, Constants.SAVE_MY_ACCOUNT_RESPONSE, Constants.HIDE_MY_ACCOUNT_SAVE_SUCCESS,
    Constants.RESET_MY_ACCOUNT,
    {   //modify here
        id: undefined,
        first: '',
        last: '',
        initials: '',
        User: { username: '' },
        OAccounts: []
    });

const wrappedReducer = (state, action) => {
    state = reducer(state, action);
    if (action.type === Constants.GET_MY_ACCOUNT_RESPONSE && !state.err && !state.error) {
        if (state.User && state.User.Sessions && state.User.Sessions.length > 0) {
            state.current_oaccount_id = state.User.Sessions[0].type_id;
        }
    }

    return state;
};

export default wrappedReducer;
