import Qs from "qs";
import Moment from "moment";

const clientSessionOfflineQuery = (state, offlineQuery) => {

    const entitiesById = state.entities.ClientSessions.byId;

    const idsByQuery = { ...state.collections.ClientSessions.idsByQuery };
    const itemsByQuery = { ...state.collections.ClientSessions.itemsByQuery };
    const pagesByQuery = { ...state.collections.ClientSessions.pagesByQuery };

    const qParts = Qs.parse(offlineQuery);
    let limit = qParts.limit || 'all';
    let page = qParts.page || 1;

    //we know limit, startDate, endDate, complete, and sort
    let complete = qParts.complete || null;
    let startDate = qParts.startDate ? Moment(qParts.startDate) : null;
    
    //we add a day to get the ones that happen on that day - Not exactly sure why we need this but that caused a bug so let's remove it for now
    // let endDate = qParts.endDate ? Moment(qParts.endDate).add(1, 'days') : null;
    let endDate = qParts.endDate ? Moment(qParts.endDate) : null;

    let sort = qParts.sort || '-endDate';
    let collection = [];
    Object.keys(entitiesById).forEach((key) => {
        const cs = entitiesById[key];
        if (!cs.id || !cs.createdAt) {
            //This is an unfilled reference.  These can be there when another object has a reference to this
            //client session but didn't bring the object down with it.
            return;
        }
        if (startDate) {
            if (!Moment(cs.start_time).isAfter(startDate)) {
                return;
            }
        }
        if (endDate) {
            if (!Moment(cs.start_time).isBefore(endDate)) {
                return;
            }
        }
        if (complete && cs.complete !== complete) {
            return;
        }
        collection.push(cs);
    });

    if (sort.startsWith('-')) {
        sort = sort.substring(1);
    }
    let sortFunction;
    if (sort === 'startDate') {
        sortFunction = (a, b) => {
            return (new Date(b.start_time) - new Date(a.start_time));
        }
    } else if (sort === '-startDate') {
        sortFunction = (a, b) => {
            return (new Date(a.start_time) - new Date(b.start_time));
        }
    } else if (sort === '-endDate') {
        sortFunction = (a, b) => {
            return (new Date(b.end_time) - new Date(a.end_time));
        }
    } else {
        sortFunction = (a, b) => {
            return (new Date(a.end_time) - new Date(b.end_time));
        }
    }

    collection.sort(sortFunction);
    if (limit !== 'all') {
        collection = collection.slice((page - 1) * limit, page * limit);
    }

    const items = {
        begin: 0,
        end: 0,
        limit,
        total: collection.length
    };

    const pages = {
        current: 0,
        hasNext: false,
        hasPrev: false,
        next: 0,
        prev: 0,
        total: 0
    };

    if (limit !== 'all') {
        items.begin = (page - 1) * limit;
        items.end = (page) * limit;
        pages.current = page;
        pages.hasNext = items.end < items.total;
        pages.hasPrev = items.begin > 0;
        pages.prev = page - 1;
        pages.next = page + 1;
        pages.total = Math.ceil(collection.length / parseInt(limit));

    } else {
        items.begin = 1;
        items.end = collection.length;
        pages.current = 1;
        pages.total = 1;
    }

    //we don't do the paging stuff right now
    idsByQuery[offlineQuery] = collection.map(c => c.id);
    itemsByQuery[offlineQuery] = items;
    pagesByQuery[offlineQuery] = pages;

    state = {
        ...state,
        collections: {
            ...state.collections,
            ClientSessions: {
                ...state.collections.ClientSessions,
                idsByQuery: idsByQuery,
                itemsByQuery: itemsByQuery,
                pagesByQuery: pagesByQuery
            }
        }
    };

    return state;
};

const clientSessionQuery = (state, isNew, remove) => {

    //do the best we can but mostly just get the newest
    //entry to show up first
    if (!isNew && !remove) {
        return state;
    }

    const entitiesById = state.entities.ClientSessions.byId;

    const idsByQuery = state.collections.ClientSessions.idsByQuery;
    const itemsByQuery = state.collections.ClientSessions.itemsByQuery;

    const newIdsByQuery = {};
    const newItemsByQuery = {};
    Object.keys(idsByQuery).forEach((query) => {
        const qParts = Qs.parse(query);
        const newItems = { ...itemsByQuery[query] };
        //we know limit, startDate, endDate, complete, and sort
        let limit = qParts.limit || 'all';
        let complete = qParts.complete || null;
        let startDate = qParts.startDate ? Moment(qParts.startDate) : null;
        
        //we add a day to get the ones that happen on that day - Not exactly sure why we need this but that caused a bug so let's remove it for now
        // let endDate = qParts.endDate ? Moment(qParts.endDate).add(1, 'days') : null;
        let endDate = qParts.endDate ? Moment(qParts.endDate) : null;

        let sort = qParts.sort || '-endDate';
        let collection = [];
        Object.keys(entitiesById).forEach((key) => {
            const cs = entitiesById[key];
            if (!cs.id) {
                //This is an unfilled reference.  These can be there when another object has a reference to this
                //client session but didn't bring the object down with it.
                return;
            }
            if (startDate) {
                if (!Moment(cs.start_time).isAfter(startDate)) {
                    return;
                }
            }
            if (endDate) {
                if (!Moment(cs.start_time).isBefore(endDate)) {
                    return;
                }
            }
            if (complete && cs.complete !== complete) {
                return;
            }
            collection.push(cs);
        });

        if (sort.startsWith('-')) {
            sort = sort.substring(1);
        }
        let sortFunction;
        if (sort === 'startDate') {
            sortFunction = (a, b) => {
                return (new Date(b.start_time) - new Date(a.start_time));
            }
        } else if (sort === '-startDate') {
            sortFunction = (a, b) => {
                return (new Date(a.start_time) - new Date(b.start_time));
            }
        } else if (sort === '-endDate') {
            sortFunction = (a, b) => {
                return (new Date(b.end_time) - new Date(a.end_time));
            }
        } else {
            sortFunction = (a, b) => {
                return (new Date(a.end_time) - new Date(b.end_time));
            }
        }

        collection.sort(sortFunction);

        if (isNew) {
            newItems.total = newItems.total + 1;
        }

        if (limit !== 'all') {
            limit = parseInt(limit);
            collection = collection.slice((page - 1) * limit, page * limit);
        }
        //we don't do the paging stuff right now
        newIdsByQuery[query] = collection.map(c => c.id);
        newItemsByQuery[query] = newItems;
    });

    state = {
        ...state,
        collections: {
            ...state.collections,
            ClientSessions: {
                ...state.collections.ClientSessions,
                idsByQuery: newIdsByQuery,
                itemsByQuery: newItemsByQuery
            }
        }
    };

    return state;
};

const notesOfflineQuery = (state, offlineQuery) => {

    const entitiesById = state.entities.Notes.byId;

    const idsByQuery = { ...state.collections.Notes.idsByQuery };
    const itemsByQuery = { ...state.collections.Notes.itemsByQuery };
    const pagesByQuery = { ...state.collections.Notes.pagesByQuery };

    const qParts = Qs.parse(offlineQuery);
    let limit = qParts.limit || 'all';
    let page = qParts.page || 1;

    //we know limit, startDate, endDate, complete, and sort
    let complete = qParts.complete || null;
    let pSdRId = qParts.pSdRId;
    let type = qParts.type;
    let startDate = qParts.startDate ? Moment(qParts.startDate) : null;
    //we add a day to get the ones that happen on that day
    let endDate = qParts.endDate ? Moment(qParts.endDate).add(1, 'days') : null;
    let sort = qParts.sort || '-endDate';
    let collection = [];
    Object.keys(entitiesById).forEach((key) => {
        const cs = entitiesById[key];
        if (startDate) {
            if (!Moment(cs.start_time).isAfter(startDate)) {
                return;
            }
        }
        if (endDate) {
            if (!Moment(cs.start_time).isBefore(endDate)) {
                return;
            }
        }
        if (complete && cs.complete !== complete) {
            return;
        }
        if (pSdRId && cs.PSdRId !== pSdRId) {
            return;
        }
        if (type && cs.type !== type) {
            return;
        }

        collection.push(cs);
    });

    if (sort.startsWith('-')) {
        sort = sort.substring(1);
    }
    let sortFunction;
    if (sort === 'startDate') {
        sortFunction = (a, b) => {
            return (new Date(b.start_time) - new Date(a.start_time));
        }
    } else if (sort === '-startDate') {
        sortFunction = (a, b) => {
            return (new Date(a.start_time) - new Date(b.start_time));
        }
    } else if (sort === '-endDate') {
        sortFunction = (a, b) => {
            return (new Date(b.end_time) - new Date(a.end_time));
        }
    } else {
        sortFunction = (a, b) => {
            return (new Date(a.end_time) - new Date(b.end_time));
        }
    }

    collection.sort(sortFunction);
    if (limit !== 'all') {
        collection = collection.slice((page - 1) * limit, page * limit);
    }

    const items = {
        begin: 0,
        end: 0,
        limit,
        total: collection.length
    };

    const pages = {
        current: 0,
        hasNext: false,
        hasPrev: false,
        next: 0,
        prev: 0,
        total: 0
    };

    if (limit !== 'all') {
        items.begin = (page - 1) * limit;
        items.end = (page) * limit;
        pages.current = page;
        pages.hasNext = items.end < items.total;
        pages.hasPrev = items.begin > 0;
        pages.prev = page - 1;
        pages.next = page + 1;
        pages.total = Math.ceil(collection.length / parseInt(limit));

    } else {
        items.begin = 1;
        items.end = collection.length;
        pages.current = 1;
        pages.total = 1;
    }

    //we don't do the paging stuff right now
    idsByQuery[offlineQuery] = collection.map(c => c.id);
    itemsByQuery[offlineQuery] = items;
    pagesByQuery[offlineQuery] = pages;

    state = {
        ...state,
        collections: {
            ...state.collections,
            Notes: {
                ...state.collections.Notes,
                idsByQuery: idsByQuery,
                itemsByQuery: itemsByQuery,
                pagesByQuery: pagesByQuery
            }
        }
    };

    return state;
};

const notesQueryAdjust = (state, isNew, remove) => {

    //do the best we can but mostly just get the newest
    //entry to show up first
    if (!isNew && !remove) {
        return state;
    }

    const entitiesById = state.entities.Notes.byId;

    const idsByQuery = state.collections.Notes.idsByQuery;
    const itemsByQuery = state.collections.Notes.itemsByQuery;

    const newIdsByQuery = {};
    const newItemsByQuery = {};
    Object.keys(idsByQuery).forEach((query) => {
        const qParts = Qs.parse(query);
        const newItems = { ...itemsByQuery[query] };
        //we know limit, type, page, sort
        let limit = qParts.limit || 10;
        let type = qParts.type || '';
        let page = qParts.page || '';
        let sort = qParts.sort || '-createdAt';
        let collection = [];
        Object.keys(entitiesById).forEach((key) => {
            const note = entitiesById[key];
            if (type && (type === note.type)) {
                collection.push(note);
            }
        });
        if (sort.startsWith('-')) {
            sort = sort.substring(1);
        }

        let sortFunction;
        if (sort === '-createdAt') {
            sortFunction = (a, b) => {
                return (new Date(a.createdAt) - new Date(b.createdAt));
            };
        } else if (sort === 'createdAt') {
            sortFunction = (a, b) => {
                return (new Date(b.createdAt) - new Date(a.createdAt));
            };
        }
        collection.sort(sortFunction);

        if (isNew) {
            newItems.total = newItems.total + 1;
        }

        if (limit !== 'all') {
            limit = parseInt(limit);
            collection = collection.slice((page - 1) * limit, page * limit);
        }
        newIdsByQuery[query] = collection.map(c => c.id);
        newItemsByQuery[query] = newItems;
    });

    state = {
        ...state,
        collections: {
            ...state.collections,
            Notes: {
                ...state.collections.Notes,
                idsByQuery: newIdsByQuery,
                itemsByQuery: newItemsByQuery
            }
        }
    };

    return state;
};

const pLessonsOfflineQuery = (state, offlineQuery) => {

    const entitiesById = state.entities.PLessons.byId;

    const idsByQuery = { ...state.collections.PLessons.idsByQuery };
    const itemsByQuery = { ...state.collections.PLessons.itemsByQuery };
    const pagesByQuery = { ...state.collections.PLessons.pagesByQuery };

    const qParts = Qs.parse(offlineQuery);
    let limit = qParts.limit || 'all';
    let page = qParts.page || 1;

    //we know limit, startDate, endDate, complete, and sort
    let complete = qParts.complete || null;
    //let pSdRId = qParts.pSdRId;
    let type = qParts.type;
    //let startDate = qParts.startDate ? Moment(qParts.startDate) : null;
    //we add a day to get the ones that happen on that day
    //let endDate = qParts.endDate ? Moment(qParts.endDate).add(1, 'days') : null;
    let collection = [];
    Object.keys(entitiesById).forEach((key) => {
        const cs = entitiesById[key];
        if (complete && cs.complete !== complete) {
            return;
        }
        if (type && cs.type !== type) {
            return;
        }

        collection.push(cs);
    });

    if (limit !== 'all') {
        collection = collection.slice((page - 1) * limit, page * limit);
    }

    const items = {
        begin: 0,
        end: 0,
        limit,
        total: collection.length
    };

    const pages = {
        current: 0,
        hasNext: false,
        hasPrev: false,
        next: 0,
        prev: 0,
        total: 0
    };

    if (limit !== 'all') {
        items.begin = (page - 1) * limit;
        items.end = (page) * limit;
        pages.current = page;
        pages.hasNext = items.end < items.total;
        pages.hasPrev = items.begin > 0;
        pages.prev = page - 1;
        pages.next = page + 1;
        pages.total = Math.ceil(collection.length / parseInt(limit));

    } else {
        items.begin = 1;
        items.end = collection.length;
        pages.current = 1;
        pages.total = 1;
    }

    //we don't do the paging stuff right now
    idsByQuery[offlineQuery] = collection.map(c => c.id);
    itemsByQuery[offlineQuery] = items;
    pagesByQuery[offlineQuery] = pages;

    state = {
        ...state,
        collections: {
            ...state.collections,
            PLessons: {
                ...state.collections.PLessons,
                idsByQuery: idsByQuery,
                itemsByQuery: itemsByQuery,
                pagesByQuery: pagesByQuery
            }
        }
    };

    return state;
};

const accountName = (state, obj, entityName, tableName) => {
    const key = entityName === 'OAccount' ? 'account_id' : 'AccountId';
    const account = state.entities.Accounts.byId[obj[key]];
    let accountName = '';
    let initials = '';

    // For accounts that are not populated, it might be nice to have flat store to have default values.
    // When it's filling in relations for objects that are not on the initial download or in the case of
    // Client Account may never be downloaded
    if (account && account.first && account.last) {
        accountName = account.first + ' ' + account.last;
        initials = account.initials ? account.initials : account.first.substr(0, 1) + account.last.substr(0, 1);
    }
    if (accountName !== obj.accountName) {
        obj = { ...obj, accountName, initials };
    }
    return obj;
};

const Schemas = {

    ClientSession: {
        primitives: ['id', 'complete', 'start_time', 'end_time', 'createdAt', 'updatedAt', 'version', 'LessonPlanId',
            'OrganizationId', 'ClientId', 'OAccountId', 'AppointmentId', 'manual', 'stats_data'],
        localPrimitives: [],
        belongsTo: ['Client', 'OAccount', 'Appointment'],
        hasMany: ['DataSheet', 'Trial', 'SessionNotesEntry'],
        belongsToMany: [],
        queryAdjust: clientSessionQuery,
        offlineQuery: clientSessionOfflineQuery
    },

    ClientSessionsStat: {
        id: 'ClientId-ClientSessionId',
        primitives: ['type', 'data', 'OrganizationId', 'ClientId', 'ClientSessionId', 'version'],
        localPrimitives: [],
        belongsTo: ['ClientSession'],
        hasMany: [],
        belongsToMany: [],
        queryAdjust: clientSessionQuery,
        offlineQuery: clientSessionOfflineQuery
    },

    Client: {
        primitives: ['id', 'active', 'diagnosis', 'bf_client_id', 'createdAt', 'updatedAt', 'version',
            'AccountId', 'OrganizationId', 'SchoolDistrictId',
            'emergency_contacts', 'doctors', 'special_information', 'caregivers',
            'medications', 'allergies', 'dietary_restrictions', 'other_health_information', 'special_instructions',
            'locations', 'parking', 'dress_code', 'other', 'authorized_persons'
        ],
        localPrimitives: [],
        belongsTo: ['Account'],
        hasMany: ['ClientsOAccount', 'ClientsAuthorization', 'ClientsLocation', 'LessonPlan'],
        belongsToMany: [{ entity: 'OAccount', through: 'ClientsOAccount' }],
        extras: { accountName }
    },

    OAccount: {
        primitives: ['id', 'active', 'createdAt', 'updatedAt', 'version',
            'account_id', 'organization_id'
        ],
        localPrimitives: [],
        belongsTo: ['Account'], hasMany: ['OAccountsOAdminGroup'], belongsToMany: [],
        extras: { accountName }
    },
    ClientsOAccount: {
        id: 'ClientId-OAccountId-LessonPlanId-type',
        primitives: ['ClientId', 'OAccountId', 'LessonPlanId', 'type', 'settings', 'version'],
        localPrimitives: [],
        hasMany: [],
        belongsTo: ['OAccount', 'Client'],
        belongsToMany: []
    },
    Account: {
        primitives: ['id', 'title', 'first', 'middle', 'last', 'suffix',
            'address1', 'address2', 'city', 'state', 'zip', 'country', 'primary_language', 'dob',
            'gender', 'mobile_phone', 'home_phone', 'createdAt', 'updatedAt', 'version', 'user_id', 'initials'
        ],
        localPrimitives: [],
        belongsTo: [{ entity: 'User', foreignKey: 'user_id' }],
        hasMany: [], belongsToMany: []
    },
    DataSheet: {
        id: 'id',
        primitives: ['id', 'started', 'complete', 'complete_time', 'start_time', 'end_time', 'ds', 'conflict', 'ds_init_values', 'createdAt', 'updatedAt',
            'setting', 'setting_other', 'notes', 'version', 'ClientSessionId', 'OrganizationId', 'ClientId', 'TherapistId', 'PLessonId', 'PSdRId', 'sequence', 'no_conflict_sequence', 'status', 'MaintenancePSdRId', 'PSdRsDataSheetId', 'observation_times', 'manual', 'client_session_start_time'],
        localPrimitives: ['client_complete'],
        belongsTo: ['PLesson', 'PSdR', 'MaintenancePSdR', 'ClientSession', 'OAccount', 'PSdRsDataSheet'],
        hasMany: ['Trial', 'DataSheetsPTarget', 'PProblemBxesDataSheet', 'PPBxesGoalsLessonsDataSheet'],
        belongsToMany: [{ entity: 'PTarget', through: 'DataSheetsPTarget' }, {
            entity: 'PProblemBx',
            through: 'PProblemBxesDataSheet'
        }],
    },
    DataSheetsPTarget: {
        id: 'DataSheetId-PTargetId-SubTargetId',
        primitives: ['DataSheetId', 'PTargetId', 'SubTargetId', 'part', 'place', 'version'],
        localPrimitives: [],
        hasMany: [],
        belongsTo: ['DataSheet', 'PProblemBx', 'PTarget'],
        belongsToMany: []
    },
    DataSheetsSequence: {
        id: 'ClientId-PSdRId-PProblemBxId-MaintenancePSdRId',
        primitives: ['id', 'ClientId', 'DataSheetId', 'ClientId', 'PLessonId', 'PSdRId', 'MaintenancePSdRId', 'PProblemBxId', 'no_conflict_sequence', 'version'],
        localPrimitives: [],
        hasMany: [],
        belongsTo: ['DataSheet', 'PProblemBx', 'PSdR'],
        belongsToMany: []
    },
    DevDomain: {
        primitives: ['id', 'name', 'version'], 
        localPrimitives: [],
        hasMany: [], belongsTo: [], belongsToMany: []
    },
    PLesson: {
        primitives: ['id', 'type', 'complete', 'active', 'original_name', 'name', 'goal', 'long_term_goal',
            'original_goal', 'original_long_term_goal', 'sds_complete', 'sds_total', 'createdAt', 'updatedAt', 'LessonPlanId', 'FundingSourceId',
            'version', 'ClientId', 'DevDomainId', 'OrganizationId', 'LessonId', 'definition', 'purpose', 'min_months', 'max_months', 'materials', 'prompt_examples',
            'modification_examples', 'generalization_examples', 'references', 'related', 'deletedAt'],
        localPrimitives: [],
        belongsTo: ['DevDomain', 'Lesson'],
        hasMany: ['PSdR', 'MaintenancePSdR', 'PTarget', 'DataSheet'],
        belongsToMany: [],
        offlineQuery: pLessonsOfflineQuery
    },
    PSdR: {
        primitives: ['id', 'complete', 'active', 'original_sd', 'sd', 'sd_example', 'original_response', 'response', 'response_example', 'goal',
            'progress_goal', 'original_goal', 'original_progress_goal', 'due_date', 'context_structured_setting', 'LessonPlanId', 'FundingSourceId',
            'context_unstructured_setting', 'context_none', 'context_other', 'context_other_text', 'prompt_level',
            'prompt_level_other_text', 'setting_home', 'setting_school', 'setting_clinic', 'setting_community',
            'setting_other', 'setting_other_text', 'unit_of_measurement', 'unit_of_measurement_other_text',
            'measured_by_technician', 'measured_by_teacher', 'measured_by_parent', 'measured_by_other', 'measured_by_other_text',
            'aba_method', 'aba_methods', 'aba_method_other_text', 'aba_methods_notes', 'loc_therapy_room', 'loc_table', 'loc_floor', 'loc_around_house',
            'loc_park', 'loc_classroom', 'loc_other', 'loc_other_text', 'loc_notes', 'stimuli_type_3d', 'stimuli_type_2d', 'stimuli_type_none',
            'stimuli_type_photos', 'stimuli_type_cartoons', 'stimuli_type_other', 'stimuli_type_other_text', 'stimuli_type_notes', 'field_size',
            'field_size_other_text', 'field_size_notes', 'field_orientation', 'field_orientation_other_text', 'field_orientation_notes', 'measurement_procedure',
            'measurement_procedure_other_text', 'measurement_procedure_notes', 'mc_percent', 'mc_percent_other_text', 'mc_sessions',
            'mc_sessions_other_text', 'mc_people', 'mc_people_other_text', 'mc_consecutive_independent_trials',
            'mc_consecutive_independent_trials_other_text', 'est_session_length', 'est_session_length_other_text', 'est_session_length_notes',
            'schedule', 'schedule_other_text', 'schedule_notes', 'pt_FP', 'pt_PP',
            'pt_FV', 'pt_PV', 'pt_FVi', 'pt_PVi', 'pt_FM', 'pt_PM', 'pt_FG', 'pt_PG', 'pt_FPo', 'pt_PPo', 'pt_LQS', 'pt_other', 'pt_other_text', 'pt_notes',
            'prompt_fading_method', 'prompt_fading_method_other_text', 'prompt_fading_method_notes', 'prompt_fading_criteria', 'prompt_fading_criteria_other_text', 'prompt_fading_criteria_notes',
            'error_correction_method', 'error_correction_method_other_text', 'error_correction_method_notes', 'pam_observe', 'pam_ask', 'pam_test', 'pam_forced_choice',
            'pam_mswo', 'pam_other', 'pam_other_text', 'pam_notes', 'rit_edible', 'rit_sensory', 'rit_tangible', 'rit_activity', 'rit_social',
            'rit_other', 'rit_other_text', 'rit_notes', 'reinforcer_schedule', 'reinforcer_schedule_other_text', 'reinforcer_schedule_notes', 'reinforcer_schedule_number', 'reinforcer_schedule_number_other_text',
            'specialized_reinforcer_schedule', 'specialized_reinforcer_schedule_other_text', 'specialized_reinforcer_schedule_notes', 'gm_teach_sufficient_ex', 'gm_gca', 'gm_stimulus_equiv', 'gm_use_common_stimuli',
            'gm_teach_loosely', 'gm_teach_loosely_people', 'gm_teach_loosely_location', 'gm_teach_loosely_positions', 'gm_teach_loosely_tone_of_voice',
            'gm_teach_loosely_choice_of_words', 'gm_teach_loosely_variety_of_angles', 'gm_teach_loosely_other_people_present',
            'gm_teach_loosely_clothes_attire', 'gm_teach_loosely_lighting', 'gm_teach_loosely_background_noise', 'gm_teach_loosely_time_of_day',
            'gm_teach_loosely_temperature_smells', 'gm_teach_loosely_other', 'gm_teach_loosely_other_text', 'gm_mediate_generally', 'gm_instruct_client_to_g', 'gm_srplus_r_variability',
            'gm_teach_to_req_levels', 'gm_int_srplus', 'gm_delayed_srplus', 'gm_indiscriminate_contingency', 'gm_behavior_traps',
            'gm_ask_people_to_sr_g', 'gm_teach_srplus_recruiting', 'gm_other', 'gm_other_text', 'gm_notes', 'gen_mc_percent', 'gen_mc_percent_other_text',
            'gen_mc_sessions', 'gen_mc_sessions_other_text', 'gen_mc_people', 'gen_mc_people_other_text', 'gen_mc_consecutive_independent_trials', 'gen_mc_other_text',
            'gen_mc_consecutive_independent_trials_other_text', 'gen_mc_settings', 'pp_inside_session_observes', 'pp_inside_session_runs_lesson', 'pp_inside_session_gives_srplus',
            'pp_inside_session_takes_data', 'pp_inside_session_gen_maintenance', 'pp_inside_session_other', 'pp_inside_session_other_text', 'pp_inside_session_notes',
            'pp_outside_session_runs_lesson', 'pp_outside_session_gives_srplus', 'pp_outside_session_takes_data', 'pp_outside_session_gen_maintenance',
            'pp_outside_session_other', 'pp_outside_session_other_text', 'pp_outside_session_notes', 'ds', 'ds_data', 'place', 'lesson_place', 'PLessonId', 'OrganizationId', 'SdRId', 'effective_date', 'deletedAt', 'PSdRsDataSheetId', 'version',
        ], 
        localPrimitives: [],
        belongsTo: ['PLesson'], hasMany: ['PTarget', 'Note', 'PSdRsDataSheet', 'PSdRsGoal'], belongsToMany: []
    },
    PSdRsDataSheet: {
        primitives: ['id', 'ds', 'ds_data', 'archivedAt', 'createdAt', 'updatedAt', 'version', 'PSdRId', 'OrganizationId', 'ClientId'],
        localPrimitives: [],
        belongsTo: ['PSdR'], hasMany: [], belongsToMany: []
    },
    PSdRsGoal: {
        primitives: ['id', 'met', 'met_criteria', 'goal', 'goal_target_count', 'archived_at'],
        localPrimitives: [],
        belongsTo: ['PSdR'], hasMany: [], belongsToMany: []
    },
    MaintenancePSdR: {
        primitives: ['id', 'name', 'complete', 'active', 'context_structured_setting',
            'context_unstructured_setting', 'context_none', 'context_other', 'context_other_text', 'prompt_level',
            'prompt_level_other_text', 'setting_home', 'setting_school', 'setting_clinic', 'setting_community',
            'setting_other', 'setting_other_text', 'unit_of_measurement', 'unit_of_measurement_other_text',
            'measured_by_technician', 'measured_by_teacher', 'measured_by_parent', 'measured_by_other', 'measured_by_other_text',
            'aba_method', 'aba_methods', 'aba_method_other_text', 'aba_methods_notes', 'loc_therapy_room', 'loc_table', 'loc_floor', 'loc_around_house',
            'loc_park', 'loc_classroom', 'loc_other', 'loc_other_text', 'loc_notes', 'stimuli_type_3d', 'stimuli_type_2d', 'stimuli_type_none',
            'stimuli_type_photos', 'stimuli_type_cartoons', 'stimuli_type_other', 'stimuli_type_other_text', 'stimuli_type_notes', 'field_size',
            'field_size_other_text', 'field_size_notes', 'field_orientation', 'field_orientation_other_text', 'field_orientation_notes', 'measurement_procedure',
            'measurement_procedure_other_text', 'measurement_procedure_notes', 'mc_percent', 'mc_percent_other_text', 'mc_sessions',
            'mc_sessions_other_text', 'mc_people', 'mc_people_other_text', 'mc_consecutive_independent_trials',
            'mc_consecutive_independent_trials_other_text', 'est_session_length', 'est_session_length_other_text', 'est_session_length_notes', 'schedule', 'schedule_other_text', 'schedule_notes', 'pt_FP', 'pt_PP',
            'pt_FV', 'pt_PV', 'pt_FVi', 'pt_PVi', 'pt_FM', 'pt_PM', 'pt_FG', 'pt_PG', 'pt_FPo', 'pt_PPo', 'pt_LQS', 'pt_other', 'pt_other_text', 'pt_notes',
            'prompt_fading_method', 'prompt_fading_method_other_text', 'prompt_fading_method_notes', 'prompt_fading_criteria', 'prompt_fading_criteria_other_text', 'prompt_fading_criteria_notes',
            'error_correction_method', 'error_correction_method_other_text', 'error_correction_method_notes', 'pam_observe', 'pam_ask', 'pam_test', 'pam_forced_choice',
            'pam_mswo', 'pam_other', 'pam_other_text', 'pam_notes', 'rit_edible', 'rit_sensory', 'rit_tangible', 'rit_activity', 'rit_social',
            'rit_other', 'rit_other_text', 'rit_notes', 'reinforcer_schedule', 'reinforcer_schedule_other_text', 'reinforcer_schedule_notes', 'reinforcer_schedule_number', 'reinforcer_schedule_number_other_text',
            'specialized_reinforcer_schedule', 'specialized_reinforcer_schedule_other_text', 'specialized_reinforcer_schedule_notes', 'gm_teach_sufficient_ex', 'gm_gca', 'gm_stimulus_equiv', 'gm_use_common_stimuli',
            'gm_teach_loosely', 'gm_teach_loosely_people', 'gm_teach_loosely_location', 'gm_teach_loosely_positions', 'gm_teach_loosely_tone_of_voice',
            'gm_teach_loosely_choice_of_words', 'gm_teach_loosely_variety_of_angles', 'gm_teach_loosely_other_people_present',
            'gm_teach_loosely_clothes_attire', 'gm_teach_loosely_lighting', 'gm_teach_loosely_background_noise', 'gm_teach_loosely_time_of_day',
            'gm_teach_loosely_temperature_smells', 'gm_teach_loosely_other', 'gm_teach_loosely_other_text', 'gm_mediate_generally', 'gm_instruct_client_to_g', 'gm_srplus_r_variability',
            'gm_teach_to_req_levels', 'gm_int_srplus', 'gm_delayed_srplus', 'gm_indiscriminate_contingency', 'gm_behavior_traps',
            'gm_ask_people_to_sr_g', 'gm_teach_srplus_recruiting', 'gm_other', 'gm_other_text', 'gm_notes', 'pp_inside_session_observes', 'pp_inside_session_runs_lesson', 'pp_inside_session_gives_srplus',
            'pp_inside_session_takes_data', 'pp_inside_session_gen_maintenance', 'pp_inside_session_other', 'pp_inside_session_other_text', 'pp_inside_session_notes',
            'pp_outside_session_runs_lesson', 'pp_outside_session_gives_srplus', 'pp_outside_session_takes_data', 'pp_outside_session_gen_maintenance',
            'pp_outside_session_other', 'pp_outside_session_other_text', 'pp_outside_session_notes', 'ds', 'ds_data', 'PLessonId', 'OrganizationId', 'PSdRId', 'effective_date', 'deletedAt', 'version',
        ], 
        localPrimitives: [],
        belongsTo: ['PLesson'], hasMany: ['Note'], belongsToMany: []
    },
    PTarget: {
        primitives: ['id', 'name', 'original_name', 'goal', 'progress_goal', 'place', 'lesson_place', 'createdAt', 'updatedAt',
            'version', 'PSdRId', 'OrganizationId', 'TargetId', 'PLessonId', 'complete', 'history', 'intro_date', 'mastery_date', 'mastery_data_sheets', 'sub_targets', 'probing_sub_targets', 'deletedAt', 'maintenance_active', 'sub_targets_history'],
        localPrimitives: [],
        belongsTo: ['PLesson', 'PSdR'],
        hasMany: [],
        belongsToMany: [{ entity: 'DataSheet', through: 'DataSheetsPTarget' }]
    },
    PProblemBx: {
        tableName: 'PProblemBxes',
        primitives: ['id', 'active', 'name', 'short_name', 'goal', 'progress', 'reduction_type', 'reduction_percent_start', 'reduction_percent_end', 'reduction_start', 'LessonPlanId', 'FundingSourceId',
            'reduction_end', 'reduction_length', 'reduction_unit', 'setting', 'measured_by', 'definition', 'antecedent_adverse_stimulus',
            'antecedent_adverse_stimulus_ant_ex', 'antecedent_adverse_stimulus_cons_ex', 'antecedent_lacks_attn', 'antecedent_lacks_attn_ant_ex',
            'antecedent_lacks_attn_cons_ex', 'antecedent_lacks_tangible', 'antecedent_lacks_tangible_ant_ex', 'antecedent_lacks_tangible_cons_ex',
            'antecedent_lacks_sensory', 'antecedent_lacks_sensory_ant_ex', 'antecedent_lacks_sensory_cons_ex', 'emergency_intervention',
            'calm_bx_indicator', 'calm_intervention_class', 'trigger_bx_indicator', 'trigger_intervention_class', 'agitation_bx_indicator',
            'agitation_intervention_class', 'acceleration_bx_indicator', 'acceleration_intervention_class', 'peak_bx_indicator', 'peak_intervention_class',
            'deesclation_bx_indicator', 'deesclation_intervention_class', 'recovery_bx_indicator', 'recovery_intervention_class', 'due_date',
            'effective_date', 'ds', 'ds_data', 'place', 'version', 'ClientId', 'OrganizationId', 'ProblemBxId', 'SignatureId', 'joined_data', 'version', 'deletedAt', 'complete', 'baseline', 'status', 'start_baseline_date', 'is_published', 'start_bip_date', 'revised_bip', 'PProblemBxesDataSheetTypeId'],
        localPrimitives: [],
        belongsTo: ['Signature'], hasMany: ['PAntecedentIntervention', 'PConsequenceIntervention', 'Note', 'PProblemBxesLesson', 'PPBxesGoal', 'PProblemBxesDataSheetType', 'PProblemBxesHistory'],
        belongsToMany: [{entity: 'Lesson', through: 'PProblemBxesLesson'}, {
            entity: 'DataSheet',
            through: 'PProblemBxesDataSheet'
        }]
    },
    PProblemBxesHistory: {
        tableName: 'PProblemBxesHistory',
        primitives: ['id', 'createdAt', 'deletedAt', 'complete', 'baseline', 'status', 'start_baseline_date', 'is_published', 'is_signed_entry', 'start_bip_date', 'revised_bip', 'PProblemBxesDataSheetTypeId', 'reduction_length', 'reduction_unit', 'ds', 'version'],
        localPrimitives: [],
        belongsTo: ['PProblemBx'],
        hasMany: [],
        belongsToMany: []
    },
    PProblemBxesDataSheet: {
        id: 'PProblemBxId-DataSheetId',
        primitives: ['DataSheetId', 'PProblemBxId', 'place', 'sequence', 'conflict', 'Prev_DataSheetId', 'no_conflict_sequence', 'version', 'PPBxesGoalId', 'mastery_data', 'PProblemBxesDataSheetTypeId', 'ds', 'ds_data'],
        localPrimitives: [],
        hasMany: [],
        belongsTo: ['DataSheet', 'PProblemBx', 'PProblemBxesDataSheetType'],
        belongsToMany: []
    },
    PProblemBxesDataSheetType: {
        primitives: ['id', 'reduction_type', 'reduction_percent_start', 'reduction_frequency_start', 'reduction_start', 'reduction_length', 'reduction_unit', 'ds', 'ds_data', 'createdAt', 'archivedAt', 'PProblemBxId'],
        localPrimitives: [],
        hasMany: [],
        belongsTo: ['PProblemBx'],
        belongsToMany: []
    },
    Lesson: {
        primitives: ['id', 'name', 'active', 'devDomainId', 'definition', 'purpose', 'min_months', 'max_months', 'materials', 'prompt_examples',
            'modification_examples', 'generalization_examples', 'references', 'related', 'version'],
        localPrimitives: [],
        belongsTo: [], hasMany: ['PLesson'], belongsToMany: [{ entity: 'PProblemBx', through: 'PProblemBxesLesson' }]
    },
    PProblemBxesLesson: {
        id: 'PProblemBxId-LessonId-type-name-editId',
        primitives: ['PProblemBxId', 'LessonId', 'type', 'example', 'place', 'version', 'name'],
        localPrimitives: [],
        hasMany: [],
        belongsTo: ['Lesson'],
        belongsToMany: []
    },
    PPBxesGoal: {
        primitives: [
            'id', 'place', 'introduced', 'met', 'replacement_behaviors_met', 'due_date', 'measurement', 'reduction_end', 'reduction_percent_end',
            'reduction_frequency_end', 'timeframe', 'timeframe_unit', 'active', 'PProblemBxId', 'phase_met', 'rb_not_required', 'deletedAt', 'DeletedOAccountId', 'PProblemBxesDataSheetTypeId'
        ],
        localPrimitives: [],
        hasMany: ['PPBxesGoalsLesson'],
        belongsTo: ['PProblemBx'],
        belongsToMany: []
    },
    PPBxesGoalsLesson: {
        primitives: [
            'id', 'name', 'type', 'met', 'archived', 'introduced',
            'ds', 'ds_type', 'ds_data', 'measurement_type', 'measurement_length', 'measurement_unit', 
            'goal_measurement', 'goal_start', 'goal_end', 'goal_timeframe_unit', 'goal_timeframe',
            'PProblemBxId', 'PPBxesGoalId', 'deletedAt', 'DeletedOAccountId', 'PPBxesGoalsLessonsDataSheetTypeId', 'version',
        ],
        localPrimitives: [],
        hasMany: ['PPBxesGoalsLessonsDataSheetType'],
        belongsTo: ['PPBxesGoal'],
        belongsToMany: []
    },
    Trial: {
        primitives: [
            'ClientId', 'ClientSessionId', 'DataSheetId', 'OAccountId', 'OrganizationId', 'PLessonId', 'PProblemBxId', 'PSdRId', 'MaintenancePSdRId', 'PTargetId', 'SubTargetId',
            'abc_structured_a', 'abc_structured_a_other_text', 'abc_structured_b', 'abc_structured_b_other_text', 'abc_structured_c', 'abc_structured_c_other_text',
            'abc_structured_replacement_b', 'abc_structured_replacement_b_other_text', 'abc_structured_sub_b', 'abc_structured_sub_b_other_text',
            'antecedent', 'bx', 'c', 'complete', 'consequence', 'disc_step', 'ds_sequence_no', 'end_time', 'fg', 'fm', 'fp', 'fpo',
            'ftc_p_begin', 'ftc_p_end', 'fv', 'fvi', 'i', 'id', 'incorrect_non_response', 'incorrect_non_response_text', 'incorrect_other_target',
            'incorrect_other_target_text', 'incorrect_problem_behavior', 'incorrect_problem_behavior_text', 'lqs', 'notes', 'out_of_view', 'p',
            'p_other', 'p_other_text', 'pg', 'place', 'pm', 'pp', 'ppo', 'problem_bx_occurred', 'pv', 'pvi', 'reward_attn_play_game', 'reward_attn_play_game_text',
            'reward_attn_praise', 'reward_attn_praise_text', 'reward_escape_non_preferred_task_activity', 'reward_escape_non_preferred_task_activity_tex',
            'reward_sensory_perseverative_item_activity', 'reward_sensory_perseverative_item_activity_te', 'reward_tangible_edible', 'reward_tangible_edible_text',
            'reward_tangible_token', 'reward_tangible_token_text', 'reward_tangible_toy', 'reward_tangible_toy_text', 'start_time', 'createdAt', 'version', 'PSdRsDataSheetId', 'total_trials',
            'PPBxesGoalsLessonId', 'PPBxesGoalId', 'PProblemBxesDataSheetTypeId', 'PPBxesGoalsLessonsDataSheetTypeId',
        ],
        localPrimitives: [],
        hasMany: [], belongsTo: ['DataSheet', 'ClientSession'], belongsToMany: []
    },
    PAntecedentIntervention: {
        primitives: ['id', 'AntecedentInterventionId', 'intervention', 'definition', 'example', 'type', 'PProblemBxId', 'place', 'version'],
        localPrimitives: [],
        hasMany: [],
        belongsTo: ['PProblemBx'],
        belongsToMany: []
    },
    PConsequenceIntervention: {
        primitives: ['id', 'ConsequenceInterventionId', 'intervention', 'definition', 'example', 'type', 'PProblemBxId', 'place', 'version'],
        localPrimitives: [],
        hasMany: [],
        belongsTo: ['PProblemBx'],
        belongsToMany: []
    },
    Note: {
        primitives: ['id', 'notes', 'createdAt', 'acknowledgers', 'sequence_no', 'ClientId', 'OAccountId', 'OrganizationId', 'PLessonId', 'PProblemBxId', 'PSdRId', 'MaintenancePSdRId', 'LessonPlanId', 'type', 'version'],
        localPrimitives: [],
        hasMany: [],
        belongsTo: ['OAccount'],
        belongsToMany: [],
        queryAdjust: notesQueryAdjust,
        offlineQuery: notesOfflineQuery
    },
    SignificantEvent: {
        primitives: ['id', 'name', 'createdAt', 'updatedAt', 'version', 'short_description', 'event_date', 'adaptive_behavior', 'problem_behavior', 'parent_training', 'adaptive_maintenance', 'OAccountId', 'OrganizationId', 'ClientId'],
        localPrimitives: [],
        hasMany: [],
        belongsTo: [],
        belongsToMany: [],
    },
    DeptPosition: {
        primitives: ['id', 'name', 'OAdminGroupId', 'version'],
        localPrimitives: [],
        hasMany: ['OAccountsOAdminGroup'],
        belongsTo: [],
        belongsToMany: [],
    },
    OAccountsOAdminGroup: {
        id: 'OAdminGroupId-OAccountId',
        primitives: ['OAdminGroupId', 'OAccountId', 'DeptPositionId', 'version'],
        localPrimitives: [],
        hasMany: [],
        belongsTo: ['OAccount', 'DeptPosition'],
        belongsToMany: [],
    },
    ClientActivity: {
        tableName: 'ClientActivities',
        primitives: ['id', 'message', 'createdAt', 'ClientId', 'OAccountId', 'LessonPlanId', 'OrganizationId', 'PLessonId', 'PProblemBxId', 'PSdRId', 'MaintenancePSdRId', 'version'],
        localPrimitives: [],
        hasMany: [],
        belongsTo: ['OAccount'],
        belongsToMany: [],
    },
    FundingSourcesGroup: {
        primitives: ['id', 'active', 'name', 'external_funding_source_id', 'version'],
        localPrimitives: [],
        hasMany: [],
        belongsTo: [],
        belongsToMany: [],
    },
    FundingSource: {
        primitives: ['id', 'active', 'name', 'external_funding_source_id', 'version'],
        localPrimitives: [],
        hasMany: [],
        belongsTo: [],
        belongsToMany: [{ entity: 'SessionNote', through: 'SessionNotesMatchingRule' }],
    },
    SessionNotesType: {
        primitives: ['id', 'active', 'name', 'OrganizationId', 'version'],
        localPrimitives: [],
        hasMany: [],
        belongsTo: [],
        belongsToMany: [],
    },
    ServiceType: {
        primitives: ['id', 'active', 'name', 'external_service_type_id', 'version'],
        localPrimitives: [],
        hasMany: [],
        belongsTo: [],
        belongsToMany: [{ entity: 'SessionNote', through: 'SessionNotesMatchingRule' }],
    },
    CPTCode: {
        primitives: ['id', 'active', 'code', 'version'],
        localPrimitives: [],
        hasMany: [],
        belongsTo: [],
        belongsToMany: [{ entity: 'SessionNote', through: 'SessionNotesMatchingRule' }],
    },

    SessionNote: {
        primitives: ['id', 'active', 'fields', 'publishedAt', 'archivedAt', 'deletedAt', 'OrganizationId', 'FundingSourcesGroupId', 'OAccountId', 'SessionNotesTypeId', 'version', 'createdAt', 'updatedAt',],
        localPrimitives: [],
        hasMany: ['SessionNotesMatchingRule', 'SessionNotesLocation',  'SessionNotesSignature'],
        belongsTo: ['OAccount', 'FundingSourcesGroup', 'SessionNotesType'],
        belongsToMany: [
            { entity: 'FundingSource', through: 'SessionNotesMatchingRule' },
            { entity: 'CPTCode', through: 'SessionNotesMatchingRule' },
            { entity: 'ServiceType', through: 'SessionNotesMatchingRule' },
        ]
    },
    SessionNotesSignature: {
        primitives: ['id', 'SessionNoteId', 'SignerTypeId', 'display', 'description', 'include_date', 'include_name', 'can_sign_later', 'is_indirect_signature_optional', 'place', 'createdAt', 'updatedAt', 'version', ],
        localPrimitives: [],
        hasMany: [],
        belongsTo: ['SignerType'],
        belongsToMany: []
    },
    SessionNotesMatchingRule: {
        id: 'FundingSourceId-ServiceTypeId-CPTCodeId',  // these three are unique so we don't need SessionNoteId
        primitives: ['id', 'SessionNoteId', 'FundingSourceId', 'ServiceTypeId', 'CPTCodeId', 'createdAt', 'updatedAt', 'version'],
        localPrimitives: [],
        hasMany: [],
        belongsTo: ['SessionNote', 'FundingSource', 'ServiceType', 'CPTCode'],
        belongsToMany: [],
    },
    SessionNotesLocation: {
        id: 'SessionNoteId-LocationId',  // these three are unique so we don't need SessionNoteId
        primitives: ['SessionNoteId', 'LocationId', 'createdAt', 'updatedAt', 'version'],
        localPrimitives: [],
        hasMany: [],
        belongsTo: ['SessionNote', 'GeoLocation'],
        belongsToMany: [],
    },
    Appointment: {
        primitives: ['id', 'client_first_name', 'client_last_name', 'scheduled_from', 'scheduled_to', 'completed_from', 'completed_to', 'employee_first_name', 'employee_last_name', 'location', 'OrganizationId', 'ClientId', 'OAccountId', 'version', "ServiceTypeId", "CPTCodeId", "FundingSourceId", "telehealth", 'InsuranceId', 'time_zone_offset', 'approvedAt', 'ApprovedOAccountId', 'requestedAt', 'service_sub_type', 'funding_source_type'],
        localPrimitives: [],
        hasMany: [],
        belongsTo: ['Client', 'OAccount', 'ServiceType', 'CPTCode', 'FundingSource'],
        belongsToMany: [],
    },
    SessionNotesEntry: {
        tableName: 'SessionNotesEntries',
        primitives: ['id', 'template', 'fields', 'signature', 'OrganizationId', 'ClientId', 'OAccountId', 'SessionNoteId', 'AppointmentId', 'ClientSessionId', 'FundingSourcesGroupId', 'SessionNotesTypeId', 'ServiceTypeId', 'CPTCodeId', 'FundingSourceId', 'createdAt', 'updatedAt', 'completedAt', 'deletedAt', 'type', 'conflict', 'SessionNotesEntryId', 'estimatedAppointmentStartDate', 'LessonPlanId', 'estimatedAppointmentEndDate', 'lock', 'SubstituteOAccountId',  'version'],
        localPrimitives: [],
        hasMany: ['SessionNotesEntriesSignature'],
        belongsTo: ['Client', 'OAccount', 'SessionNote', 'Appointment', 'ClientSession', 'ServiceType'],
        belongsToMany: [],
    },
    SessionNotesEntriesSignature: {
        primitives: ['id', 'SessionNotesEntryId', 'SignerTypeId', 'OAccountId', 'ClientId', 'LessonPlanId', 'display', 'description', 'other_name', 'signature', 'include_date', 'include_name', 'can_sign_later', 'is_indirect_signature_optional', 'is_required', 'signed_date', 'is_signed', 'is_other', 'place', 'createdAt', 'updatedAt', 'version'],
        localPrimitives: [],
        hasMany: [],
        belongsTo: ['SignerType', 'SessionNotesEntry'],
        belongsToMany: [],
    },
    ClientsAuthorization: {
        primitives: ['id', 'start_date', 'end_date', 'OrganizationId', 'ClientId', 'version', "ServiceTypeId", "CPTCodeId", "FundingSourceId", 'InsuranceId'],
        localPrimitives: [],
        hasMany: [],
        belongsTo: ['Client', 'ServiceType', 'CPTCode', 'FundingSource'],
        belongsToMany: [],
    },
    ClientsLocation: {
        id: 'ClientId-LocationId',
        primitives: ['ClientId', 'LocationId', 'LessonPlanId', 'createdAt', 'updatedAt', 'version'],
        localPrimitives: [],
        hasMany: [],
        belongsTo: ['Client', { entity: 'GeoLocation', foreignKey: 'LocationId' }],
        belongsToMany: [],
    },
    GeoLocation: {
        tableName: 'GeoLocations',
        primitives: ['id', 'name', 'OrganizationId', 'createdAt', 'updatedAt', 'version', 'time_zone_name'],
        localPrimitives: [],
        hasMany: ['ClientsLocation'],
        belongsTo: [],
        belongsToMany: [],
    },
    User: {
        primitives: ['id', 'email', 'version'],
        localPrimitives: [],
        hasMany: [],
        belongsTo: [],
        belongsToMany: [],
    },
    Signature: {
        primitives: ['id', 'signed_date'],
        localPrimitives: [],
        hasMany: [],
        belongsTo: [],
        belongsToMany: [],
    },
    LessonPlan: {
        primitives: ['id', 'name', 'locations', 'type', 'authorized_persons', 'active', 'parking', 'dress_code', 'other', 'ClientId', 'OrganizationId'],
        localPrimitives: [],
        hasMany: [],
        belongsTo: ['Client'],
        belongsToMany: [],
    },
    PPBxesGoalsLessonsDataSheet: {
        id: 'PProblemBxId-PPBxesGoalId-PPBxesGoalsLessonId-DataSheetId',
        primitives: ['DataSheetId', 'PProblemBxId', 'PPBxesGoalsLessonId', 'PPBxesGoalId', 'observation_times', 'mastery_data', 'ds', 'ds_data', 'PPBxesGoalsLessonsDataSheetTypeId', 'version'],
        localPrimitives: [],
        hasMany: [],
        belongsTo: ['PProblemBx', 'PPBxesGoal', 'PPBxesGoalsLesson', 'DataSheet'],
        belongsToMany: [],
    },
    PPBxesGoalsLessonsDataSheetType: {
        primitives: ['id', 'goal_start', 'measurement_type', 'measurement_length', 'measurement_unit', 'ds', 'ds_type', 'ds_data', 'archivedAt', 'createdAt', 'updatedAt', 'version', 'PPBxesGoalsLessonId'],
        localPrimitives: [],
        hasMany: [],
        belongsTo: ['PPBxesGoalsLesson', 'PPBxesGoalsLessonsDataSheet'],
        belongsToMany: [],
    },
    SignerType: {
        primitives: ['id', 'label', 'type', 'createdAt',  'updatedAt', 'version'],
        localPrimitives: [],
        hasMany: [],
        belongsTo: [],
        belongsToMany: [],
    },
};

Schemas.getTableName = (entityName) => {
    const schema = Schemas[entityName];
    return schema.tableName ? schema.tableName : entityName + 's';
};

export default Schemas;
