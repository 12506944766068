'use strict';

import * as Redux from 'redux';
import PTrainings from './p-trainings/search/reducers/results';
import PTrainingsProgress from './p-trainings/search/reducers/progress';
import PCertificates from './p-certificates/search/reducers/results';
import PTests from './p-tests/search/reducers/results';
import PVideos from './p-videos/search/reducers/results';
import PResources from './p-resources/search/reducers/results';
import MyAccount from './reducers/my-account';
import MyClients from './reducers/my-clients';
import MyOrganization from './reducers/my-organization.js';
import FeatureFlags from './reducers/feature-flags';
import { reducer as Current } from './client-sessions/current/reducers/current';
import Storage from './reducers/storage';
import Logout from './reducers/logout';


export default Redux.createStore(
    Redux.combineReducers({
        logout: Logout,
        storage: Storage,
        current: Current,
        myAccount: MyAccount,
        myClients: MyClients,
        trainings: PTrainings,
        trainingsProgress: PTrainingsProgress,
        pCertificates: PCertificates,
        pTests: PTests,
        pVideos: PVideos,
        pResources: PResources,
        myOrganization: MyOrganization,
        featureFlags: FeatureFlags,
    })
);
