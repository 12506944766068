'use strict';
import FluxConstant from 'flux-constant';


export default FluxConstant.set([
    'RESET',
    'GET_RESULTS',
    'GET_RESULTS_RESPONSE',
    'GET_INDIVIDUAL_STATUS',
    'GET_INDIVIDUAL_STATUS_RESPONSE'
]);
